import React, { useEffect, useState } from 'react';
import Navbar from '../inc/Navbar';
// import profilepic from '../../images/user.webp';
import './account.css';
import Swal from 'sweetalert2';
import { API_URL } from '../../config/config';
import axios from 'axios';
import moment from 'moment';
import LoadingText from '../inc/LoadingText';
import Loading from '../inc/Loading';
import { toast } from 'react-toastify';
const Accountsettings = () => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [isloading, setIsloading] = useState(true);

  useEffect(() => {
    getUser();
  }, []);
  const url = `${API_URL}profile`;
  const headers = {
    Authorization: `${localStorage.getItem('token')}`,
  };

  const getUser = async () => {
    try {
      const response = await axios.get(url, { headers: headers });
      const data = await response.data;
      console.log(data);
      setFirstname(data.fname);
      setLastname(data.lname);
      setEmail(data.email);
      setPhone(data.phone);
      setDob(data.dob);
      setGender(data.gender);
      setIsloading(false);
    } catch (error) {
      // error code
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }

      console.log(error);
      toast.error(error.response.data);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    setSubmitting(true);

    try {
      const url = `${API_URL}profile`;
      const body = {
        fname: firstname,
        lname: lastname,
        phone: phone,
        dob: dob,
        gender: gender,
      };
      await axios.put(url, body, {
        headers: headers,
      });
      setSubmitting(false);
      toast.success('profile updated Successfully');
    } catch (error) {
      console.log(error);
      setSubmitting(false);
      toast.error(error.response.data);
    }
  };

  return (
    <>
      <Navbar />
      <div className='profileSettings'>
        <div className='container'>
          <h3 className='mt-5'>Profile</h3>
          <div className='row'>
            {/* <div className="col-sm-6 mt-5">
              <p className="mt-5">Profile photo</p>
              <div className="photo-container">
                <div>
                  <img src={profilepic} alt="profile" />
                </div>
                <div>
                  <button className="btn text-success ml-3 uploadBtn">
                    <i className="fa-solid fa-arrow-up-from-bracket"></i>{' '}
                    <span>Upload avatar</span>
                  </button>
                </div>
                <div>
                  <button className="btn btn ml-3 trashBtn">
                    <i className="fa-solid fa-trash "></i>
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <div className='container '>
        <div className='card mt-5'>
          <div className='card-body'>
            {isloading ? (
              <Loading />
            ) : (
              <div className='row mt-3'>
                <div className='col-sm-10 offset-md-1'>
                  <form
                    action=''
                    className='mt-3 accountForm'
                    onSubmit={(e) => submitHandler(e)}>
                    <div className='row'>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label htmlFor=''> First name</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='First name'
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                          />
                        </div>
                        <div className='form-group'>
                          <label htmlFor=''> Gender</label>
                          <select
                            className='form-control'
                            onChange={(e) => setGender(e.target.value)}>
                            <option value=''>Select Gender </option>
                            {gender === 'Male' ? (
                              <option value='Male' selected>
                                {' '}
                                Male{' '}
                              </option>
                            ) : (
                              <option value='Male'>Male</option>
                            )}
                            {gender === 'Female' ? (
                              <option value='Female' selected>
                                Female
                              </option>
                            ) : (
                              <option value='Female'>Female</option>
                            )}
                          </select>
                        </div>
                        <div className='form-group'>
                          <label htmlFor=''>Email </label>
                          <input
                            type='email'
                            className='form-control'
                            placeholder='Email'
                            value={email}
                            disabled
                          />
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='form-group'>
                          <label htmlFor=''>Last name</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Last name'
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                          />
                        </div>
                        <div className='form-group'>
                          <label htmlFor=''>Date of Birth (optional)</label>
                          <input
                            type='date'
                            className='form-control'
                            value={moment(dob, 'DD-MM-YYYY').format(
                              'YYYY-MM-DD'
                            )}
                            onChange={(e) =>
                              setDob(
                                moment(e.target.value, 'YYYY-MM-DD').format(
                                  'DD-MM-YYYY'
                                )
                              )
                            }
                          />
                        </div>
                        <div className='form-group'>
                          <label htmlFor=''>Mobile</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Mobile'
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className='col-sm-3'></div>
                      <div className='col-sm-6 mt-4'>
                        <button
                          className='btn btn  btn-block updateBtn '
                          disabled={submitting}
                          type='submit'>
                          {submitting ? (
                            <LoadingText />
                          ) : (
                            <> Update my profile</>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Accountsettings;
