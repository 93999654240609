import React, { useEffect, useState } from 'react';
import './categories.css';
import { colorScheme, iconScheme } from '../../config/dataScheme';
import axios from 'axios';
import { API_URL } from '../../config/config';
import Navbar from '../inc/Navbar';
import Swal from 'sweetalert2';
import LoadingText from '../inc/LoadingText';
import Loading from '../inc/Loading';
import NotFound from '../inc/NotFound';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Primaryloading from '../inc/Primaryloading';

const Categories = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  const [currentColor, setCurrentColor] = useState('');

  const [currentIcon, setCurrentIcon] = useState('');

  const [categoryname, setCategoryName] = useState('');

  const [transactionCount, setTransactionCount] = useState(0);

  const [currentCategory, setCurrentCategory] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const headers = {
    Authorization: `${localStorage.getItem('token')}`,
  };
  const getCategories = async () => {
    try {
      const url = `${API_URL}category`;
      const response = await axios.get(url, { headers: headers });
      const data = await response.data;
      setCategories(data);
      setIsLoading(true);

      // loop thorugh the icons and colors and assign them to the categories
    } catch (error) {
      // error code is 401
      // if (error.response.status === 401) {
      // localStorage.removeItem('token');
      // window.location.href='/'
      // return
      // }
      // alert(error.response.data.message);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();

    // check icon is not empty
    console.log(currentCategory);

    if (currentIcon === '') {
      Swal.fire({ icon: 'error', text: 'Please select an icon' });
      return;
    }
    // check color is not empty
    if (currentColor === '') {
      Swal.fire({ icon: 'error', text: 'Please select a color' });
      return;
    }

    setIsSubmiting(true);

    try {
      const body = {
        name: categoryname,
        color: currentColor,
        icon: currentIcon,
        trasactionCount: transactionCount,
      };

      // currentCategory is null or not
      if (currentCategory === null) {
        const response = await axios.post(`${API_URL}category`, body, {
          headers: headers,
        });
        console.log(response);
        setCategories([...categories, response.data.data]);
        // Swal.fire('Success', 'Category added successfully', 'success');
        toast.success('Category Added Successfully');
      } else {
        await axios.put(`${API_URL}category/${currentCategory._id}`, body, {
          headers: headers,
        });
        const newCategory = {
          _id: currentCategory._id,
          name: categoryname,
          color: currentColor,
          icon: currentIcon,
        };
        // replace the old category with this new category on setCategories
        const newCategories = categories.map((category) =>
          category._id === newCategory._id ? newCategory : category
        );

        setCategories(newCategories);
        toast.success('Category Updated successfully');
      }
      setIsSubmiting(false);
      setCategoryName('');
      setCurrentColor('');
      setCurrentIcon('');
      setTransactionCount('');
      setCurrentCategory(null);
    } catch (error) {
      console.log(error);
      alert(error.message);
    }
  };

  const deleteHandler = async (id) => {
    const url = `${API_URL}category/${id}`;

    const res = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (res.isConfirmed) {
      try {
        setCategories(categories.filter((category) => category._id !== id));
        await axios.delete(url, { headers: headers });
      } catch (error) {
        // Swal.fire('Error', 'Something went wrong', 'error');
        toast.error('something went wrong');
      }
    }
  };

  const editHandler = async (category) => {
    setCurrentIcon(category.icon);
    setCurrentColor(category.color);
    setCategoryName(category.name);
    setCurrentCategory(category);
  };

  return (
    <>
      <Navbar />
      <div className="full-body categories_page">
        <div className="container">
          <div className="row cat-section">
            <div className="col-sm-12">
              <h3>Create a new category</h3>
              <div className="card mt-3 border-rounded">
                {!isLoading ? (
                  <Primaryloading />
                ) : (
                  <div className="card-body">
                    <form className="row" onSubmit={(e) => submitHandler(e)}>
                      <div className="col-sm-2">
                        <div className="dropdown ">
                          <label htmlFor="" className="button-label">
                            Color
                          </label>
                          <button
                            type="button"
                            className="btn btn-white dropdown-toggle  color-btn  "
                            data-toggle="dropdown">
                            {/* to copy */}
                            {currentColor ? (
                              <span
                                className="color-outer"
                                style={{
                                  borderColor:
                                    colorScheme[currentColor - 1].code,
                                }}>
                                <span
                                  className="color-inner"
                                  style={{
                                    backgroundColor:
                                      colorScheme[currentColor - 1].code,
                                  }}></span>
                              </span>
                            ) : (
                              <span className="color-outer">
                                <span className="color-inner"></span>
                              </span>
                            )}
                          </button>
                          <div className="dropdown-menu color-select">
                            {colorScheme.map((color, index) => {
                              return currentColor === color.id ? (
                                <span
                                  onClick={() => {
                                    setCurrentColor(color.id);
                                  }}
                                  className="color active"
                                  key={index}
                                  style={{
                                    backgroundColor: color.code,
                                  }}></span>
                              ) : (
                                <span
                                  onClick={() => {
                                    setCurrentColor(color.id);
                                  }}
                                  className="color "
                                  key={index}
                                  style={{
                                    backgroundColor: color.code,
                                  }}></span>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-2">
                        <div className="dropdown">
                          <label htmlFor="" className="button-label">
                            icons
                          </label>
                          <button
                            type="button"
                            className="btn btn-white dropdown-toggle icon-btn"
                            data-toggle="dropdown">
                            {/* to changre */}

                            {currentColor ? (
                              <span
                                className=" icon-outer color-inner"
                                style={{
                                  background:
                                    colorScheme[currentColor - 1].code,
                                }}>
                                {currentIcon ? (
                                  <ion-icon
                                    name={
                                      iconScheme[currentIcon - 1].name
                                    }></ion-icon>
                                ) : (
                                  <></>
                                )}
                              </span>
                            ) : (
                              <span className=" icon-outer color-inner">
                                <span className="icon-inner">
                                  {currentIcon ? (
                                    <ion-icon
                                      name={
                                        iconScheme[currentIcon - 1].name
                                      }></ion-icon>
                                  ) : (
                                    <></>
                                  )}
                                </span>
                              </span>
                            )}
                          </button>
                          <div className="dropdown-menu  icon-select">
                            <div className="icon-handle">
                              {iconScheme.map((icon, index) => {
                                return (
                                  <div
                                    className="cat-icon"
                                    key={index}
                                    onClick={() => setCurrentIcon(icon.id)}>
                                    <ion-icon name={icon.name} />
                                    <span
                                      className="icon active"
                                      key={index}
                                      style={{
                                        backgroundColor: icon.name,
                                      }}></span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          required
                          ref={(input) => input && input.focus()}
                          value={categoryname}
                          onChange={(e) => setCategoryName(e.target.value)}
                        />
                      </div>
                      <div className="col-sm-3 category-select ">
                        <button
                          type="submit"
                          disabled={isSubmiting}
                          className="btn  btn-block category-btn ">
                          {isSubmiting ? (
                            <LoadingText />
                          ) : (
                            <>
                              {currentCategory ? 'Update' : 'Create'} Category
                            </>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="transactionSection">
          <div className="container">
            <h3 className="mt-5">Categories</h3>
            <div className="row">
              <div className="col-sm-12">
                <div className="card mt-3">
                  <div className="card-body">
                    {isLoading ? (
                      <div className="row">
                        <div className="col-sm-12">
                          {categories && categories.length > 0 ? (
                            <div className="table table-responsive">
                              <table className="table table-borderless">
                                <tbody>
                                  {categories.map((category, index) => {
                                    return (
                                      <tr key={category._id}>
                                        <td>
                                          <div className="icon-container">
                                            <div
                                              className="icon-holder"
                                              style={{
                                                backgroundColor:
                                                  colorScheme[
                                                    category.color - 1
                                                  ].code,
                                              }}>
                                              <ion-icon
                                                name={
                                                  iconScheme[category.icon - 1]
                                                    .name
                                                }></ion-icon>
                                            </div>
                                            <b className="text-capitalize">
                                              {category.name}
                                            </b>
                                          </div>
                                        </td>
                                        <td>
                                          {category.transactionCount > 0 ? (
                                            <>
                                              {category.transactionCount > 1 ? (
                                                <>
                                                  {category.transactionCount}{' '}
                                                  Transactions
                                                </>
                                              ) : (
                                                <>
                                                  {category.transactionCount}{' '}
                                                  Transaction
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>No Transaction</>
                                          )}
                                          <p className="transaction-title">
                                            {category.count}
                                          </p>
                                        </td>
                                        <td className="text-right">
                                          <button
                                            className="btn btn-event btn-xs mr-2"
                                            onClick={() => {
                                              navigate(
                                                `/category/${category._id}`
                                              );
                                            }}>
                                            <ion-icon name="eye-outline"></ion-icon>
                                          </button>
                                          <button
                                            className="btn btn-event btn-xs mr-2"
                                            onClick={() =>
                                              editHandler(category)
                                            }>
                                            <ion-icon name="create-outline"></ion-icon>
                                          </button>
                                          <button
                                            className="btn btn-event btn-xs"
                                            onClick={() =>
                                              deleteHandler(category._id)
                                            }>
                                            <ion-icon name="trash-outline"></ion-icon>
                                          </button>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <NotFound
                              title={'Oops !'}
                              subtitle={"You don't have any category yet "}
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Categories;
