import React from 'react';

const NotFound = ({ title, subtitle }) => {
  return (
    <div className='row'>
      <div className='col-sm-12 text-center pb-5'>
        <lottie-player
          src='https://assets10.lottiefiles.com/private_files/lf30_rrpywigs.json'
          background='transparent'
          className='m-auto'
          loop
          autoplay
          style={{
            height: '300px',
            width: '300px',
            margin: 'auto',
          }}></lottie-player>
        <div className='no-found-desc-text'>
          <h1>{title}</h1>
          <p>{subtitle} </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
