import React from 'react';

const Walletloading = () => {
  return (
    <>
      <lottie-player
        src='https://assets5.lottiefiles.com/packages/lf20_lqnl5fdk.json'
        background='transparent'
        className='m-auto'
        loop
        autoplay
        style={{
          height: '30px',
          width: '300px',
          margin: 'auto',
        }}></lottie-player>
    </>
  );
};

export default Walletloading;
