import MainBuilder from './routes/MainBuilder';
import NoAuthBuilder from './routes/NoAuthBuilder';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  // check token is present
  const token = localStorage.getItem('token');
  if (token) {
    return (
      <>
        <MainBuilder />{' '}
        <ToastContainer
          position='top-right'
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          theme='colored'
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </>
    );
  } else {
    return (
      <>
        <NoAuthBuilder /> <ToastContainer />
      </>
    );
  }
};

export default App;
