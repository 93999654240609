import { useState } from 'react';
import googleIcon from './google-icon.png';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import axios from 'axios';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { API_URL } from '../../config/config';
import Swal from 'sweetalert2';
import LoadingText from '../inc/LoadingText';

const Register = () => {
  const navigate = useNavigate();

  const auth = getAuth();

  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isSubmitting, setisSubmitting] = useState(false);

  const handleError = (error) => {
    console.log(error);
    switch (error.code) {
      case 'auth/invalid-email':
        Swal.fire('Oops', 'Invalid Email ! Please try again', 'error');
        break;
      case 'auth/user-not-found':
        Swal.fire('Oops', 'User not found ! Please try again', 'error');
        break;
      case 'auth/wrong-password':
        Swal.fire('Oops', 'Wrong Password ! Please try again', 'error');
        break;
      case 'auth/user-disabled':
        Swal.fire('Oops', 'User disabled ! Please Contact Admin', 'error');
        break;
      case 'auth/too-many-requests':
        Swal.fire(
          'Sorry !',
          'Too many attempts ! Please try again later',
          'error'
        );
        break;
      case 'auth/email-already-in-use':
        Swal.fire('Oops', 'Email already in use ! Please Login', 'error');
        break;

      default:
        console.log(error.code);
        Swal.fire('Oops', 'Something went wrong ! Please try again', 'error');
        break;
    }
  };

  const handleAPISignUp = async (token, fname, lname) => {
    try {
      const headers = {
        Authorization: `${token}`,
      };
      const body = {
        fname,
        lname,
        email,
      };

      const response = await axios.post(`${API_URL}auth/signup`, body, {
        headers,
      });
      return response.data.token;
    } catch (error) {
      Swal.fire('Oops', error.response.data.message, 'error');
      setisSubmitting(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setisSubmitting(true);

    try {
      const response = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const token = await response._tokenResponse.idToken;

      const api_token = await handleAPISignUp(token, fname, lname);

      localStorage.setItem('token', api_token);

      window.location.href = '/dashboard';
    } catch (error) {
      handleError(error);
      setisSubmitting(false);
    }
  };

  const handleGoogleButton = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();

      const response = await firebase.auth().signInWithPopup(provider);
      console.log(response.user._delegate);
      const token = await response.user._delegate.accessToken;
      const name = await response.user.displayName;
      //  split name by space
      const nameArray = name.split(' ');

      await handleAPISignUp(token, nameArray[0], nameArray[1]);

      localStorage.setItem('token', token);

      // window.location.href = '/dashboard';
    } catch (error) {
      Swal.fire('Oops', error.response.data.message, 'error');
      setisSubmitting(false);
    }
  };

  return (
    <>
      <div className="fullbackground-section">
        <section className="left-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 offset-md-2 main-login-form">
                <h3 className="page-title">Create your Account</h3>
                <p>Enter Your Details</p>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="form-group mb-3 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First name"
                      required
                      value={fname}
                      onChange={(e) => setFName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-3 ">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last name"
                      required
                      value={lname}
                      onChange={(e) => setLName(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-3 ">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="password"
                      className="form-control "
                      placeholder="Password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="btn btn-block submit  "
                      disabled={isSubmitting}>
                      {isSubmitting ? (
                        <LoadingText />
                      ) : (
                        <>Create your account</>
                      )}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-dark btn-block g-sign text-center mt-3"
                      onClick={() => handleGoogleButton()}>
                      <img src={googleIcon} alt="" />
                      Continue with Google
                    </button>
                  </div>

                  <div className="signup mt-3 text-center">
                    <p className="para">
                      Already have an account?{' '}
                      <span onClick={() => navigate('/login')} className="name">
                        Login
                      </span>{' '}
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="right-section"></section>
      </div>
    </>
  );
};

export default Register;
