import React, { useEffect, useState } from 'react';

import Navbar from '../inc/Navbar';
import { API_URL } from '../../config/config';
import axios from 'axios';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const PlanEdit = () => {
  const params = useParams();
  const { id } = params;
  const navigate = useNavigate();
  const [friends, setFriends] = useState([]);
  const [members, setMembers] = useState([]);
  const [plan, setPlan] = useState({
    name: '',
    memberType: 0,
    isAdmin: false,
    members: [],
    isMailRequired: false,
  });
  const [planTransaction, setPlanTransaction] = useState([]);
  const [currentFriend, setCurrentFriend] = useState({});
  const [friendError, setFriendError] = useState(false);

  useEffect(() => {
    getfriends();
    getTransactions();
  }, []);

  const headers = {
    Authorization: `${localStorage.getItem('token')}`,
  };
  const getfriends = async () => {
    const api_url = `${API_URL}friend`;
    try {
      const response = await axios.get(api_url, { headers: headers });
      const data = await response.data;
      setFriends(data);
    } catch (error) {
      console.log(error);
    }
  };
  const getTransactions = async () => {
    const api_url = `${API_URL}plan-transaction/${id}`;

    const response = await axios.get(api_url, { headers: headers });
    const data = await response.data.data;
    setPlan(data.plan);
    setPlanTransaction(data.planTransactions);

    setMembers(data.plan.members);
  };

  const handleFriendInput = (e) => {
    const email = e.target.value;
    const friend = friends.find((friend) => friend.email === email);
    setFriendError(false);

    if (friend) {
      // check if the friend email is already exists in plan.members
      const isMember = plan.members.find((member) => member.email === email);
      if (isMember) {
        toast.error('This friend is already added to the plan');
        setFriendError(true);
        return;
      }
      const newObj = {
        name: friend.name,
        email: friend.email,
      };
      setCurrentFriend(newObj);
    }
  };

  const handlePopupSubmit = (e) => {
    e.preventDefault();
    let newMember = {
      name: currentFriend.name,
      email: currentFriend.email,
      isAdmin: plan.isAdmin,
    };
    console.log(newMember);
    setPlan({
      ...plan,
      members: [...plan.members, newMember],
    });
    setCurrentFriend({});
    document.querySelector('#modal-close-button').click();
    toast.success('member added to the plan');
  };

  const handleMemberInput = (e) => {
    const { name, value } = e.target;
    setFriendError(false);
    if (name === 'email') {
      const isMember = plan.members.find((member) => member.email === value);
      if (isMember) {
        toast.error('This friend is already added to the plan');
        setFriendError(true);
        setCurrentFriend({ ...currentFriend, [name]: value });

        return;
      }
    }
    setCurrentFriend({ ...currentFriend, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (plan.name === '') {
      toast.error('Please enter a plan name');
      return;
    }
    if (plan.members.length === 0) {
      toast.error('Please add atleast one member to the plan');
      return;
    }

    const bodyData = {
      name: plan.name,
      members: [...plan.members],
      isMailRequired: plan.isMailRequired,
    };

    console.log(bodyData);

    const api_url = `${API_URL}plan/${id}`;
    try {
      const response = await axios.put(api_url, bodyData, {
        headers: headers,
      });
      const data = await response.data;
      console.log(data);
      toast.success('Plan Updated successfully');
      navigate('/plan');
    } catch {
      toast.error('Plan creation failed');
    }
  };

  return (
    <>
      <Navbar />

      <div className='container mt-5'>
        <div className=' '>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='card mt-5'>
                  <div className='card-body'>
                    <form action='' method='post'>
                      <div className='row'>
                        <div className='col-sm-12 mb-3 align-self-center'>
                          <h3>Plan </h3>
                        </div>
                        <div className='col-sm-4'>
                          <div className='form-group'>
                            <label htmlFor='name'>Plan Name</label>
                            <input
                              className='form-control'
                              value={plan.name}
                              required
                              onChange={(e) =>
                                setPlan({ ...plan, name: e.target.value })
                              }
                            />
                          </div>
                        </div>
                        <div className='col-sm-4'>
                          <div className='form-group'>
                            <label htmlFor='name'>Mail Required</label>
                            <select
                              className='form-control'
                              value={plan.isMailRequired}
                              onChange={(e) =>
                                setPlan({
                                  ...plan,
                                  isMailRequired: e.target.value === 'true',
                                })
                              }>
                              <option value={false}>No</option>
                              <option value={true}>Yes</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className='row my-3'>
                        <div className='col-sm-4 align-self-center'>
                          <h3>Members</h3>
                        </div>
                        <div className='col-sm-8 align-self-center text-right'>
                          <button
                            type='button'
                            className='btn btn-dark'
                            data-toggle='modal'
                            data-target='#myModal'
                            onClick={() => setCurrentFriend({})}>
                            Add Member
                          </button>
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-sm-12'>
                          <table className='table '>
                            <tbody>
                              {plan?.members.map((member, index) => (
                                <tr key={index}>
                                  <td>
                                    {member.name}
                                    <span className='ml-2'>
                                      {member.isAdmin ? (
                                        <ion-icon name='shield-checkmark-outline'></ion-icon>
                                      ) : null}
                                    </span>
                                  </td>
                                  <td>{member.email}</td>
                                  <td>
                                    {planTransaction.length === 0 &&
                                    member.type != 'owner' ? (
                                      <button
                                        type='button'
                                        className='btn btn-event btn-xs'
                                        onClick={() => {
                                          const newMembers = [...plan.members];
                                          newMembers.splice(index, 1);
                                          setPlan({
                                            ...plan,
                                            members: newMembers,
                                          });
                                        }}>
                                        <ion-icon name='trash'></ion-icon>
                                      </button>
                                    ) : (
                                      <button
                                        type='button'
                                        className='btn btn-event btn-xs disabled'
                                        onClick={() =>
                                          member.type === 'owner'
                                            ? toast.error(
                                                'You cannot delete the owner'
                                              )
                                            : toast.error(
                                                'You cannot delete after the transaction is made'
                                              )
                                        }>
                                        <ion-icon name='trash'></ion-icon>
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          {plan?.members.length === 0 && (
                            <div className='alert alert-info text-center'>
                              <strong>No members added to the plan</strong>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className='row my-3'>
                        <div className='col-sm-12 text-left'>
                          <button
                            type='submit'
                            className='btn btn-primary'
                            onClick={handleSubmit}>
                            Update Plan
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='modal addTransaction' id='myModal'>
        <div className='modal-dialog modal-dialog-centered '>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Member</h5>
              <button
                type='button'
                id='modal-close-button'
                className='close'
                data-dismiss='modal'>
                &times;
              </button>
            </div>

            <div className='modal-body'>
              <form method='post' onSubmit={handlePopupSubmit}>
                <div className='row'>
                  <div className='col-sm-12'>
                    <span
                      type='button'
                      className={`p-2 badge badge-pill  mr-2 ${
                        plan.memberType === 0
                          ? 'badge-success'
                          : 'badge-secondary'
                      }`}
                      onClick={() => setPlan({ ...plan, memberType: 0 })}>
                      Existing Friend
                    </span>
                    <span
                      type='button'
                      className={`p-2 badge badge-pill  mr-2 ${
                        plan.memberType === 1
                          ? 'badge-success'
                          : 'badge-secondary'
                      }`}
                      onClick={() => {
                        setPlan({ ...plan, memberType: 1 });
                        setCurrentFriend({});
                      }}>
                      New Member
                    </span>
                  </div>
                  <div className='col-sm-12 mt-3'>
                    {plan.memberType === 0 ? (
                      <div className='row'>
                        <div className='form-group col-sm-12'>
                          <label htmlFor='name'>Select Friend</label>
                          <select
                            className={`form-control ${
                              friendError ? 'is-invalid' : ''
                            }`}
                            onChange={(e) => handleFriendInput(e)}>
                            <option>Select Friend</option>
                            {friends.map((friend, index) => (
                              <option key={index} value={friend.email}>
                                {friend.name}- {friend.email}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className='form-group col-sm-12'>
                          <label htmlFor='admin'>
                            <input
                              type='checkbox'
                              id='admin'
                              onChange={(e) =>
                                setPlan({ ...plan, isAdmin: e.target.checked })
                              }
                              checked={plan.isAdmin}
                            />
                            <span className='ml-2'>Make as Admin </span>
                          </label>
                        </div>
                      </div>
                    ) : (
                      <div className='row'>
                        <div className='form-group col-sm-6'>
                          <label htmlFor='name'>Name</label>
                          <input
                            className={`form-control ${
                              friendError ? 'is-invalid' : ''
                            }`}
                            value={currentFriend.name}
                            onChange={(e) => handleMemberInput(e)}
                            name='name'
                            type='text'
                            required
                            placeholder='Enter Name'
                          />
                        </div>
                        <div className='form-group col-sm-6'>
                          <label htmlFor='email'>Email</label>
                          <input
                            className={`form-control ${
                              friendError ? 'is-invalid' : ''
                            }`}
                            value={currentFriend.email}
                            onChange={(e) => handleMemberInput(e)}
                            name='email'
                            type='email'
                            required
                            placeholder='Enter email'
                          />
                        </div>
                        <div className='form-group col-sm-12'>
                          <label htmlFor='admin'>
                            <input
                              type='checkbox'
                              id='admin'
                              onChange={(e) =>
                                setPlan({ ...plan, isAdmin: e.target.checked })
                              }
                              checked={plan.isAdmin}
                            />
                            <span className='ml-2'>Make as Admin </span>
                          </label>
                        </div>
                      </div>
                    )}

                    <div className='row'>
                      <div className='col-sm-12 mt-2'>
                        <button
                          type='submit'
                          className={`btn btn-dark ${
                            friendError && 'disabled'
                          }`}
                          {...(friendError && { disabled: true })}>
                          Add Member
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanEdit;
