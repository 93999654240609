import React from 'react';
import { toast } from 'react-toastify';

const NoeditButton = ({
  edit = true,
  complete = false,
  msg = 'transaction',
  extraClass,
}) => {
  const editHandler = () => {
    toast.error(`Oops ! You cannot edit this ${msg}`);
  };
  const deleteHandler = () => {
    toast.error(`Sorry! You cannot delete this ${msg}.`);
  };
  const completeHandler = () => {
    toast.error(`Sorry! You cannot Close this ${msg}.`);
  };

  return (
    <>
      {edit && (
        <button
          className={`btn btn-event btn-xs ${extraClass}`}
          onClick={() => editHandler()}>
          <ion-icon name='create-outline'></ion-icon>
        </button>
      )}

      <button
        className='btn btn-event btn-xs ml-2'
        onClick={() => deleteHandler()}>
        <ion-icon name='trash'></ion-icon>
      </button>

      {complete && (
        <button
          className='btn btn-event btn-xs ml-2'
          onClick={() => completeHandler()}>
          <ion-icon name='checkmark-done-circle-outline'></ion-icon>
        </button>
      )}
    </>
  );
};

export default NoeditButton;
