import React, { useEffect, useState } from 'react';

import Navbar from '../inc/Navbar';
import { API_URL } from '../../config/config';
import axios from 'axios';
import { iconScheme, colorScheme } from '../../config/dataScheme';
import Swal from 'sweetalert2';
import Loading from '../inc/Loading';
import LoadingText from '../inc/LoadingText';
import moment from 'moment';
import NotFound from '../inc/NotFound';
import Primaryloading from '../inc/Primaryloading';
import NoeditButton from '../inc/NoeditButton';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

const PlanTransactions = () => {
  const params = useParams();
  const { id } = params;
  const [transactions, setTransactions] = useState([]);
  const [banks, setBanks] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [primaryLoading, setPrimaryLoading] = useState(true);

  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [note, setNote] = useState();
  const [time, setTime] = useState(moment().format('HH:mm'));
  const [amount, setAmount] = useState();
  const [currentBank, setCurrentBank] = useState();
  const [plan, setPlan] = useState({});
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const [currenttransaction, setCurrenttransaction] = useState();
  const [todayExpense, setTodayExpense] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  // const [totalIncome, setTotalIncome] = useState(0);

  useEffect(() => {
    getBanks();
    getTransactions();
  }, []);

  const headers = {
    Authorization: `${localStorage.getItem('token')}`,
  };
  const getBanks = async () => {
    const api_url = `${API_URL}bank`;

    const response = await axios.get(api_url, { headers: headers });

    const data = response.data;
    setBanks(data);

    const total = response.data.reduce((acc, curr) => {
      return acc + curr.total;
    }, 0);

    setCurrentBank(data[0]);

    // setTotalAmount(total);
  };

  const getTransactions = async () => {
    const api_url = `${API_URL}plan-transaction/${id}`;

    const response = await axios.get(api_url, { headers: headers });
    const data = await response.data.data;
    setPlan(data.plan);

    console.log('plan ', data.plan);
    //    const options = [
    //   { value: 'chocolate', label: 'Chocolate' },
    //   { value: 'strawberry', label: 'Strawberry' },
    //   { value: 'vanilla', label: 'Vanilla' }
    // ]
    let membersArray = [];
    data.plan.members.map((member, index) => {
      membersArray.push({
        value: member._id,
        label: `${member.name} - (${member.email})`,
      });
    });

    setMembers(membersArray);

    var exp = 0;
    var inc = 0;
    var todayExp = 0;
    data.planTransactions.map((da) => {
      if (da?.transaction?.type === 'expense') {
        exp = exp + da.amount;
        if (
          moment(da.transaction.date).format('YYYY-MM-DD') ===
          moment().format('YYYY-MM-DD')
        ) {
          todayExp = todayExp + da.amount;
        }
      }
      // else {
      //   inc = inc + da.amount;
      // }
    });
    setTotalExpense(exp);
    setTodayExpense(todayExp);
    // setTotalIncome(inc);
    setTransactions(data.planTransactions);
    console.log(data.planTransactions);
    setIsLoaded(true);
    setPrimaryLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedMembers.length === 0) {
      toast.error('Please select at least one member');
      return;
    }
    if (!currentBank) {
      toast.error('Please select a bank');
      return;
    }

    let membersArray = [];
    selectedMembers.map((member) => {
      membersArray.push(member.value);
    });

    const body = {
      plan: id,
      bank: currentBank,
      type: 'expense',
      date: moment(date).format('DD-MM-YYYY'),
      time,
      note,
      amount,
      members: membersArray,
    };

    const api_url = `${API_URL}plan-transaction`;
    setIsSubmitting(true);

    try {
      const response = await axios.post(api_url, body, { headers: headers });
      const data = response.data.data;
      console.log(data);
      setIsSubmitting(false);
      setTransactions([
        { ...data, date: moment(data.date, 'YYYY-MM-DD').format('DD-MM-YYYY') },
        ...transactions,
      ]);
      document.querySelector('#modal-close-button').click();
      toast.success('Transaction has been added successfully');

      setDate(moment().format('YYYY-MM-DD'));
      setTime(moment().format('HH:mm'));
      setNote('');
      setAmount('');
      setSelectedMembers([]);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data);
    }
  };

  const handleDelete = async (id) => {
    const api_url = `${API_URL}plan-transaction/${id}`;

    const res = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });
    if (res.isConfirmed) {
      try {
        const body = {
          deleteTransaction: true,
        };
        await axios.delete(api_url, {
          headers: headers,
          data: body,
        });
        const filteredTransactions = transactions.filter(
          (item) => item._id !== id
        );
        setTransactions(filteredTransactions);
      } catch (error) {
        toast.error(error.response.data.message);
        // Swal.fire('Error', error.response.data.message, 'error');
      }
    }
  };

  return (
    <>
      <Navbar />

      <div className='container mt-5'>
        <div className='counter-container'>
          <div className='col-sm-12'>
            <div className='row'>
              <div className='col-sm-3'>
                <div
                  disabled={primaryLoading}
                  className={`card ${
                    plan.isAddAccess ? 'bg-success' : 'bg-danger'
                  } cardBtn `}>
                  {primaryLoading ? (
                    <Primaryloading />
                  ) : (
                    <div
                      className='card-body'
                      data-toggle='modal'
                      data-target={`${
                        plan.isAddAccess ? '#myModal' : '#nonEditable'
                      }`}
                      onClick={() => {
                        if (!plan.isAddAccess)
                          toast.error('You are not allowed to add transaction');
                      }}>
                      <div className=' justify-content-center align-items-center '>
                        <div>
                          <i className='fa-solid fa-circle-plus mr-2'></i>
                        </div>
                        <div>
                          <h5>Add Plan Expense</h5>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-3'>
                <div className='card card2'>
                  {primaryLoading ? (
                    <Primaryloading />
                  ) : (
                    <div className='card-body '>
                      <h6>Total Expenses</h6>
                      <h5 className={'text-danger'}>
                        {/* {totalExpense > 0 && '-'} */}
                        {totalExpense.toFixed(2)} INR
                      </h5>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-3'>
                <div className='card card2'>
                  {primaryLoading ? (
                    <Primaryloading />
                  ) : (
                    <div className='card-body '>
                      <h6>Today Expenses</h6>
                      <h5 className={'text-success'}>
                        {todayExpense.toFixed(2)} INR
                      </h5>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-3'>
                <div className='card card2'>
                  {primaryLoading ? (
                    <Primaryloading />
                  ) : (
                    <div className='card-body'>
                      <h6>Total Members</h6>
                      <h5 className='text-dark'>{plan.members.length}</h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='transactionSection '>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='card mt-5'>
                  <div className='card-body'>
                    {isLoaded ? (
                      <div className='row  '>
                        <table className='table table-borderless'>
                          <tbody>
                            {transactions && transactions.length > 0 ? (
                              <>
                                {transactions.map((transaction) => {
                                  return (
                                    <tr key={transaction._id}>
                                      <td>
                                        <p>{transaction.note}</p>
                                        <span className='badge badge-pill badge-xs badge-secondary'>
                                          {transaction?.user?.fname}{' '}
                                          {transaction?.user?.lname}
                                        </span>
                                      </td>
                                      <td>
                                        <p>
                                          {transaction?.transaction?.bank?.name}
                                        </p>
                                      </td>

                                      <td>
                                        <p>
                                          {moment(transaction.date).format(
                                            'DD MMM YYYY'
                                          )}
                                        </p>
                                      </td>

                                      <td className='text-right'>
                                        <h5>
                                          {Number(transaction.amount).toFixed(
                                            2
                                          )}{' '}
                                          INR
                                        </h5>
                                      </td>

                                      <td className='text-right'>
                                        <button
                                          className='btn btn-event btn-xs '
                                          type='button'
                                          data-toggle='modal'
                                          data-target='#MemberModal'
                                          onClick={() => {
                                            setCurrenttransaction(transaction);
                                          }}>
                                          <ion-icon name='people-outline'></ion-icon>
                                        </button>

                                        <button
                                          className='btn btn-event btn-xs ml-2'
                                          onClick={() =>
                                            handleDelete(transaction._id)
                                          }>
                                          <ion-icon name='trash'></ion-icon>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <NotFound
                                title={'No Transactions'}
                                subtitle={'Add a transaction to get started'}
                              />
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='modal addTransaction' id='myModal'>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Plan Transactions</h5>
              <button
                type='button'
                id='modal-close-button'
                className='close'
                data-dismiss='modal'>
                &times;
              </button>
            </div>

            <form onSubmit={(e) => handleSubmit(e)} id='add-form'>
              <div className='modal-body'>
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='col-sm-3'>
                      <label htmlFor=''>Wallet</label>
                      <select
                        name=''
                        id=''
                        className='form-control'
                        required
                        onChange={(e) => setCurrentBank(e.target.value)}>
                        {currentBank == null}
                        {banks &&
                          banks.map((bank) => (
                            <option value={bank._id}>
                              {bank.name} ({bank.total.toFixed(2)})
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className='col-sm-2'>
                      <label htmlFor=''>Date</label>
                      <input
                        className='form-control '
                        type='date'
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                        max={moment().format('YYYY-MM-DD')}
                      />
                    </div>
                    <div className='col-sm-2'>
                      <label htmlFor=''>Time</label>
                      <input
                        className='form-control '
                        type='time'
                        value={time}
                        onChange={(e) => setTime(e.target.value)}
                        required
                      />
                    </div>
                    <div className='col-sm-3'>
                      <label htmlFor=''>Note (Optional)</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Write Note'
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>
                    <div className='col-sm-2'>
                      <label htmlFor=''>Amount</label>
                      <input
                        className='form-control text-right'
                        type='number'
                        required
                        value={amount}
                        step='0.01'
                        min={1}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder='0'
                      />
                    </div>
                  </div>
                  <div className='row'></div>
                </div>
                <div className='container-fluid'>
                  <div className='row  '>
                    <div className='col-sm-10'>
                      <label>Members</label>
                      <Select
                        options={members}
                        isMulti
                        name='members[]'
                        className='basic-multi-select'
                        classNamePrefix='select'
                        value={selectedMembers}
                        onChange={setSelectedMembers}
                      />
                    </div>
                    <div className='col-sm-2 text-right mt-3'>
                      <button className='btn transactionBtn  mt-3 w-100 '>
                        {isSubmitting ? <LoadingText /> : <>Add Transaction</>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className='modal addTransaction' id='MemberModal'>
        <div className='modal-dialog modal-dialog-centered '>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Members</h5>
              <button
                type='button'
                id='modal-close-button'
                className='close'
                data-dismiss='modal'>
                &times;
              </button>
            </div>

            <div className='modal-body'>
              <div className='row'>
                <div className='col-sm-12'>
                  <table className='table'>
                    <tbody>
                      <tr className='bg-info text-white'>
                        <td>
                          <h5>Total </h5>
                        </td>
                        <td className='text-right'>
                          <h5>
                            {currenttransaction?.transaction?.amount?.toFixed(
                              2
                            )}{' '}
                            INR
                          </h5>
                        </td>
                      </tr>

                      {currenttransaction?.members.map((member) => (
                        <tr>
                          <td>
                            <p className='mb-0'>
                              {
                                plan?.members?.filter((m) => m._id == member)[0]
                                  ?.name
                              }
                            </p>
                            <small>
                              {
                                plan?.members?.filter((m) => m._id == member)[0]
                                  ?.email
                              }
                            </small>
                          </td>
                          <td className='text-right'>
                            <h5>
                              {Number(currenttransaction?.splitAmount).toFixed(
                                2
                              )}{' '}
                              INR
                            </h5>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanTransactions;
