export const colorScheme = [
  {
    id: 1,
    code: '#2c5364',
  },
  {
    id: 2,
    code: '#521A4E',
  },
  {
    id: 3,
    code: '#872A54',
  },
  {
    id: 4,
    code: '#f0534b',
  },
  {
    id: 5,
    code: '#b55a42',
  },
  {
    id: 6,
    code: '#b47b55',
  },
  {
    id: 7,
    code: '#d35e00',
  },
  {
    id: 8,
    code: '#d35e00',
  },
  {
    id: 9,
    code: '#b9965e',
  },
  {
    id: 10,
    code: '#f3a104',
  },
  {
    id: 11,
    code: '#f3a701',
  },
  {
    id: 12,
    code: '#f6cc00',
  },
  {
    id: 13,
    code: '#bed940',
  },
  {
    id: 14,
    code: '#71c644',
  },
  {
    id: 15,
    code: '#3fb372',
  },
  {
    id: 16,
    code: '#5cc5ac',
  },
  {
    id: 17,
    code: '#60cfcb',
  },
  {
    id: 18,
    code: '#48adcd',
  },
  {
    id: 19,
    code: '#4194ca',
  },
  {
    id: 20,
    code: '#4194ca',
  },
  {
    id: 21,
    code: '#4ba7e6',
  },
  {
    id: 22,
    code: '#3d75ab',
  },
  {
    id: 23,
    code: '#70a4d9',
  },
  {
    id: 24,
    code: '#6e8cb2',
  },
  {
    id: 25,
    code: '#66686b',
  },
  {
    id: 26,
    code: '#f263a0',
  },
  {
    id: 27,
    code: '#895cd6',
  },
  {
    id: 28,
    code: '#e26ceb',
  },
  {
    id: 29,
    code: '#f488b7',
  },
  {
    id: 30,
    code: '#e56274',
  },
];

export const iconScheme = [
  {
    id: 1,
    name: 'airplane-outline',
  },
  {
    id: 2,
    name: 'american-football-outline',
  },
  {
    id: 3,
    name: 'nutrition-outline',
  },
  {
    id: 4,
    name: 'car-sport',
  },
  {
    id: 5,
    name: 'barbell-outline',
  },
  {
    id: 6,
    name: 'briefcase-outline',
  },
  {
    id: 7,
    name: 'desktop-outline',
  },
  {
    id: 8,
    name: 'fast-food-outline',
  },
  {
    id: 9,
    name: 'flash-outline',
  },
  {
    id: 10,
    name: 'film-outline',
  },
  {
    id: 11,
    name: 'fitness-outline',
  },
  {
    id: 12,
    name: 'gift-outline',
  },
  {
    id: 13,
    name: 'home-outline',
  },
  {
    id: 14,
    name: 'laptop-outline',
  },
  {
    id: 15,
    name: 'library-outline',
  },
  {
    id: 16,
    name: 'location-outline',
  },
  {
    id: 17,
    name: 'newspaper-outline',
  },
  {
    id: 18,
    name: 'paw-outline',
  },
  {
    id: 19,
    name: 'people-outline',
  },
  {
    id: 20,
    name: 'pricetags-outline',
  },
  {
    id: 21,
    name: 'school-outline',
  },
  {
    id: 22,
    name: 'shirt-outline',
  },
  {
    id: 23,
    name: 'wifi-outline',
  },
  {
    id: 24,
    name: 'water-outline',
  },
  {
    id: 25,
    name: 'restaurant-outline',
  },
  {
    id: 26,
    name: 'documents-outline',
  },
  {
    id: 27,
    name: 'beer-outline',
  },
  {
    id: 28,
    name: 'cut-outline',
  },
  {
    id: 29,
    name: 'camera-outline',
  },
  {
    id: 30,
    name: 'heart-outline',
  },
  {
    id: 31,
    name: 'cafe-outline',
  },
  {
    id: 32,
    name: 'medkit-outline',
  },
  {
    id: 33,
    name: 'medical-outline',
  },
  {
    id: 34,
    name: 'file-tray-stacked-outline',
  },
  {
    id: 35,
    name: 'globe-outline',
  },
  {
    id: 36,
    name: 'headset-outline',
  },
];
