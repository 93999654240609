import React, { useEffect, useState } from 'react';

import Navbar from '../inc/Navbar';
import { API_URL } from '../../config/config';
import axios from 'axios';
import { iconScheme, colorScheme } from '../../config/dataScheme';
import Swal from 'sweetalert2';
import Loading from '../inc/Loading';
import LoadingText from '../inc/LoadingText';
import moment from 'moment';
import NotFound from '../inc/NotFound';
import Primaryloading from '../inc/Primaryloading';
import NoeditButton from '../inc/NoeditButton';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Select from 'react-select';

const PlanSummary = () => {
  const params = useParams();
  const { planId, memberId } = params;
  const id = planId;
  const [transactions, setTransactions] = useState([]);
  const [transactionSpends, setTransactionSpends] = useState([]);
  const [member, setMember] = useState();
  const [totalExpense, setTotalExpense] = useState(0);
  const [totalSpend, setTotalSpend] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);

  const [isLoaded, setIsLoaded] = useState(false);
  const [primaryLoading, setPrimaryLoading] = useState(true);

  useEffect(() => {
    getTransactions();
  }, []);

  const headers = {
    Authorization: `${localStorage.getItem('token')}`,
  };

  const getTransactions = async () => {
    const api_url = `${API_URL}plan/summary/${id}`;

    const response = await axios.get(api_url, { headers: headers });
    const data = await response.data.data;
    const currentMemberData = data.filter((item) => item._id === memberId);
    setTransactions(currentMemberData[0].transactions);
    setTransactionSpends(currentMemberData[0].transactionSpend);
    setMember(currentMemberData[0]);
    setTotalExpense(Number(currentMemberData[0].totalExp));
    setTotalSpend(Number(currentMemberData[0].totalSpend));
    setTotalBalance(Number(currentMemberData[0].balance));
    setIsLoaded(true);
    setPrimaryLoading(false);
  };

  return (
    <>
      <Navbar />

      <div className='container mt-5'>
        <div className='counter-container'>
          <div className='col-sm-12'>
            <div className='row'>
              <div className='col-sm-3'>
                <div className='card card2'>
                  {primaryLoading ? (
                    <Primaryloading />
                  ) : (
                    <div className='card-body  d-flex justify-content-center align-items-center'>
                      <h6>{member?.name}'s Trip Summary</h6>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-3'>
                <div className='card card2'>
                  {primaryLoading ? (
                    <Primaryloading />
                  ) : (
                    <div className='card-body '>
                      <h6>Total Expenses</h6>
                      <h5 className={'text-danger'}>
                        {totalExpense.toFixed(2)} INR
                      </h5>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-3'>
                <div className='card card2'>
                  {primaryLoading ? (
                    <Primaryloading />
                  ) : (
                    <div className='card-body '>
                      <h6>Total Spend</h6>
                      <h5 className={'text-success'}>
                        {totalSpend.toFixed(2)} INR
                      </h5>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-3'>
                <div className='card card2'>
                  {primaryLoading ? (
                    <Primaryloading />
                  ) : (
                    <div className='card-body'>
                      <h6>Total Balance</h6>
                      <h5
                        className={
                          totalBalance < 0 ? 'text-danger' : 'text-success'
                        }>
                        {totalBalance.toFixed(2)} INR
                      </h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='transactionSection '>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='card mt-5'>
                  <div className='card-body'>
                    {isLoaded ? (
                      <div className='row  '>
                        <table className='table table-borderless'>
                          <tbody>
                            {transactions && transactions.length > 0 ? (
                              <>
                                {transactions.map((transaction) => {
                                  return (
                                    <tr key={transaction._id}>
                                      <td>
                                        <p>{transaction.note}</p>
                                        <small>
                                          {transaction?.transaction?.bank?.name}
                                        </small>
                                      </td>
                                      <td>
                                        <p>
                                          {moment(
                                            transaction.date,
                                            'DD-MM-YYYY'
                                          ).format('DD MMM YYYY')}
                                        </p>
                                      </td>

                                      <td className='text-right'>
                                        <h5 className='text-danger'>
                                          {Number(transaction.amount).toFixed(
                                            2
                                          )}{' '}
                                          INR
                                        </h5>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <NotFound
                                title={'No Transactions'}
                                subtitle={
                                  'There is no transactions made for you '
                                }
                              />
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='card mt-5'>
                  <div className='card-body'>
                    {isLoaded ? (
                      <div className='row  '>
                        <table className='table table-borderless'>
                          <tbody>
                            {transactionSpends &&
                            transactionSpends.length > 0 ? (
                              <>
                                {transactionSpends.map((transaction) => {
                                  return (
                                    <tr key={transaction._id}>
                                      <td>
                                        <p>{transaction.note}</p>
                                      </td>
                                      <td>
                                        <p>
                                          {transaction?.transaction?.bank?.name}
                                        </p>
                                      </td>

                                      <td>
                                        <p>
                                          {moment(
                                            transaction.date,
                                            'DD-MM-YYYY'
                                          ).format('DD MMM YYYY')}
                                        </p>
                                      </td>

                                      <td className='text-right'>
                                        <h5 className='text-success'>
                                          {Number(transaction.amount).toFixed(
                                            2
                                          )}{' '}
                                          INR
                                        </h5>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <NotFound
                                title={'No Transactions'}
                                subtitle={
                                  'There is no transactions made by you'
                                }
                              />
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='transactionSection '>
          <div className='container'>
            <div className='row'></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlanSummary;
