import React, { useEffect, useState } from 'react';

import Navbar from '../inc/Navbar';
import { API_URL } from '../../config/config';
import axios from 'axios';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../inc/Loading';
import Swal from 'sweetalert2';
import NoeditButton from '../inc/NoeditButton';
import NotFound from '../inc/NotFound';

const Plan = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [currentPlan, setCurrentPlan] = useState({});
  const [plans, setPlans] = useState([]);

  useEffect(() => {
    getPlans();
  }, []);

  const headers = {
    Authorization: `${localStorage.getItem('token')}`,
  };

  const getPlans = async () => {
    try {
      const res = await axios.get(`${API_URL}plan`, { headers });
      setPlans(res.data);
      console.log(res.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (plan) => {
    const deleteRes = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });
    if (deleteRes.isConfirmed) {
      const transactionDelete = await Swal.fire({
        title: 'Do you want to delete the transaction as well?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });
      const body = {
        deleteTransaction: transactionDelete.isConfirmed,
      };
      try {
        const res = await axios.delete(`${API_URL}plan/${plan._id}`, {
          headers,
          data: body,
        });
        toast.success(res.data.message);
        getPlans();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleComplete = async (plan) => {
    const deleteRes = await Swal.fire({
      title: 'Are you sure?',
      text: 'You want to close this plan and mark it as completed',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Close  it!',
    });
    try {
      const res = await axios.put(
        `${API_URL}plan/complete/${plan._id}`,
        {},
        { headers }
      );
      toast.success(res.data.message);
      getPlans();
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  const handleSummary = (plan, member) => {
    window.location.href = `/plan/summary/${plan._id}/${member._id}`;
  };

  return (
    <>
      <Navbar />

      <div className='container mt-5'>
        <div className=' '>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12'>
                <h3 className='mb-4'>Plans &amp; Trips </h3>
                <div className='card '>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-sm-12 text-right mb-3'>
                        <a href='/plan/add' className='btn btn-dark'>
                          Create New Plan
                        </a>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-sm-12'>
                        {isLoading ? (
                          <Loading />
                        ) : (
                          <div className='table-responsive'>
                            <table className='table'>
                              {plans.length > 0 ? (
                                <tbody>
                                  {plans.map((plan, index) => (
                                    <tr key={index}>
                                      <td>{plan.name}</td>
                                      <td>
                                        {plan?.planTotalExpenses?.toFixed(2)}{' '}
                                        INR
                                      </td>
                                      <td>
                                        {plan?.members?.length || 0} Members
                                      </td>
                                      <td>
                                        {moment(plan.createdAt).format(
                                          'DD MMM YYYY'
                                        )}
                                      </td>
                                      <td>
                                        {plan?.planTransactions?.length || 0}{' '}
                                        Transactions
                                      </td>
                                      <td className='text-right'>
                                        <button
                                          className='btn btn-event btn-xs '
                                          type='button'
                                          data-toggle='modal'
                                          data-target='#myModal'
                                          onClick={() => setCurrentPlan(plan)}>
                                          <ion-icon name='people-outline'></ion-icon>
                                        </button>
                                        <a
                                          href={`/plan/view/${plan._id}`}
                                          className='btn btn-event btn-xs ml-2'>
                                          <ion-icon name='receipt-outline'></ion-icon>
                                        </a>

                                        {plan.isOwner ? (
                                          <>
                                            {plan.isCompleted ? (
                                              <>
                                                <button
                                                  onClick={() =>
                                                    toast.error(
                                                      'Plan is already completed'
                                                    )
                                                  }
                                                  className='btn btn-event btn-xs ml-2'>
                                                  <ion-icon name='create-outline'></ion-icon>
                                                </button>
                                                <button
                                                  className='btn btn-event btn-xs ml-2'
                                                  onClick={() =>
                                                    toast.error(
                                                      'Plan is already completed'
                                                    )
                                                  }>
                                                  <ion-icon name='trash'></ion-icon>
                                                </button>
                                              </>
                                            ) : (
                                              <>
                                                <a
                                                  href={`/plan/edit/${plan._id}`}
                                                  className='btn btn-event btn-xs ml-2'>
                                                  <ion-icon name='create-outline'></ion-icon>
                                                </a>
                                                <button
                                                  className='btn btn-event btn-xs ml-2'
                                                  onClick={() =>
                                                    handleDelete(plan)
                                                  }>
                                                  <ion-icon name='trash'></ion-icon>
                                                </button>
                                              </>
                                            )}

                                            <button
                                              className='btn btn-event btn-xs ml-2'
                                              onClick={() =>
                                                handleComplete(plan)
                                              }>
                                              <ion-icon name='checkmark-done-circle-outline'></ion-icon>
                                            </button>
                                          </>
                                        ) : (
                                          <NoeditButton
                                            msg={
                                              'plan bcoz you are not the owner'
                                            }
                                            complete={true}
                                            extraClass={'ml-2'}
                                          />
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <NotFound
                                  title={'No Plans'}
                                  subtitle={'Add a Plan to get started'}
                                />
                              )}
                            </table>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='modal addTransaction' id='myModal'>
        <div className='modal-dialog modal-lg modal-dialog-centered '>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Members</h5>
              <button
                type='button'
                id='modal-close-button'
                className='close'
                data-dismiss='modal'>
                &times;
              </button>
            </div>

            <div className='modal-body'>
              <div className='row'>
                <div className='col-sm-12'>
                  <div className='table-responsive'>
                    <table className='table table-striped'>
                      <tbody>
                        {currentPlan?.members?.length > 0 ? (
                          <tr>
                            <th>Member Name</th>
                            <th className='text-right'>Spend</th>
                            <th className='text-right'>Expenses</th>
                            <th className='text-right'>Balance</th>
                            <th className='text-right'>Summary</th>
                          </tr>
                        ) : (
                          <div className='alert alert-info'>
                            <strong>No members found!</strong>
                          </div>
                        )}
                        {currentPlan?.members?.map((member, index) => (
                          <tr key={index}>
                            <td>
                              {member?.name}
                              <span className='ml-2'>
                                {member?.isAdmin ? (
                                  <ion-icon name='shield-checkmark-outline'></ion-icon>
                                ) : null}
                              </span>
                            </td>
                            <td className='text-right'>
                              <span className='text-success'>
                                {member?.spend
                                  ? member?.spend?.toFixed(2)
                                  : '0.00'}{' '}
                                INR
                              </span>
                            </td>
                            <td className='text-right'>
                              <span className='text-danger'>
                                {member?.expenses
                                  ? member?.expenses?.toFixed(2)
                                  : '0.00'}{' '}
                                INR
                              </span>
                            </td>
                            <td className='text-right'>
                              {member?.balance > 0 ? (
                                <span className='text-success'>
                                  {member?.balance?.toFixed(2)} INR
                                </span>
                              ) : (
                                <span className='text-danger'>
                                  {member?.balance?.toFixed(2)} INR
                                </span>
                              )}
                            </td>
                            <td className='text-center'>
                              {member && (
                                <button
                                  className='btn btn-event btn-xs'
                                  onClick={() =>
                                    handleSummary(currentPlan, member)
                                  }>
                                  <ion-icon name='eye'></ion-icon>
                                </button>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Plan;
