import jwtDecode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import './Navbar.css';
const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const [user, setUser] = useState(null);
  const [letter, setLetter] = useState('EXPTRACKER');
  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.href = '/';
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    const decodedToken = jwtDecode(token);
    // console.log(decodedToken);
    setUser(decodedToken);
    decodedToken.fname
      ? setLetter(decodedToken.fname.charAt(0))
      : setLetter(decodedToken.email.charAt(0));
  }, []);

  const pages = [
    {
      id: 1,
      name: ' Dashboard',
      url: '/dashboard',
    },
    {
      id: 2,
      name: ' Transactions',
      url: '/transactions',
    },
    {
      id: 3,
      name: ' Transfers',
      url: '/transfers',
    },
    {
      id: 4,
      name: ' Wallets',
      url: '/wallets',
    },
    {
      id: 5,
      name: ' Friends',
      url: '/friends',
    },
    {
      id: 6,
      name: ' Categories',
      url: '/categories',
    },
    {
      id: 7,
      name: 'Bank Transfer',
      url: '/banktransfer',
    },
    {
      id: 8,
      name: 'Plan',
      url: '/plan',
    },
  ];

  return (
    <>
      <div className='navigationBar'>
        <nav className='navbar navbar-expand-md bg-white navbar-light '>
          <Link className='navbar-brand' to='/dashboard'>
            <h3>ExpTracker App</h3>
          </Link>

          <button
            className='navbar-toggler'
            type='button'
            data-toggle='collapse'
            data-target='#collapsibleNavbar'>
            <span className='navbar-toggler-icon'></span>
          </button>

          <div className='collapse navbar-collapse' id='collapsibleNavbar'>
            <ul className='navbar-nav ml-auto'>
              {pages.map(({ url, id, name }) => {
                var styleclass =
                  url === pathname ? 'nav-item active' : 'nav-item';
                return (
                  <li className={styleclass} key={id}>
                    <Link className={'nav-link'} to={url}>
                      {name}
                    </Link>
                  </li>
                );
              })}

              <li className='nav-item dropdown'>
                <a
                  className='nav-link dropdown-toggle text-dark text-capitalize'
                  href='#'
                  id='navbarDropdownMenuLink'
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='false'>
                  <b>{user && user.fname + ' ' + user.lname}</b>
                </a>
                <div
                  className='dropdown-menu mr-5'
                  aria-labelledby='navbarDropdownMenuLink'>
                  <Link className='dropdown-item' to='/account'>
                    Profile
                  </Link>
                  <a
                    className='dropdown-item'
                    href='#'
                    onClick={() => handleLogout()}>
                    Logout
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
