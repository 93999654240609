import React from 'react';
// import Lottie from 'react-lottie';

const Loading = () => {
  return (
    <div className='text-center'>
      <lottie-player
        src='https://assets1.lottiefiles.com/packages/lf20_ht6o1bdu.json'
        background='transparent'
        className='m-auto'
        loop
        autoplay
        style={{
          height: '300px',
          width: '300px',
          margin: 'auto',
        }}></lottie-player>

      {/* <Lottie options={defaultOptions} height={200} width={200} /> */}
    </div>
  );
};

export default Loading;
