import React from 'react';
// router dom
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Accountsettings from '../components/Accountsettings/Accountsettings';
import Dashboard from '../components/Dashboard/Dashboard';
import Transactions from '../components/Transactions/Transactions';
import Categories from '../components/Categories/Categories';
import Friends from '../components/Friends/Friends';
import Transfer from '../components/Transfer/Transfer';
import Managewallet from '../components/Managewallet/Managewallet';
import Banktransfer from '../components/Banktransfer/Banktransfer';
import BankTransaction from '../components/BankTransactions/Banktransactions';
import FriendsTransfer from '../components/FriendsTransfer/FriendsTransfer';
import CategoryTransactions from '../components/Categoriestransaction/CategoryTransactions';
import Plan from '../components/Plan/Plan';
import PlanAdd from '../components/Plan/PlanAdd';
import PlanTransactions from '../components/Plan/PlanTransactions';
import PlanEdit from '../components/Plan/PlanEdit';
import PlanSummary from '../components/Plan/PlanSummary';

const MainBuilder = () => {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Dashboard />} />
        <Route exact path='/dashboard' element={<Dashboard />} />
        <Route exact path='/transactions' element={<Transactions />} />
        <Route exact path='/account' element={<Accountsettings />} />
        <Route exact path='/categories' element={<Categories />} />
        <Route exact path='/category/:id' element={<CategoryTransactions />} />
        <Route exact path='/friends' element={<Friends />} />
        <Route exact path='/transfers' element={<Transfer />} />
        <Route exact path='/wallets' element={<Managewallet />} />
        <Route exact path='/Banktransfer' element={<Banktransfer />} />
        <Route exact path='/transactions/:id' element={<BankTransaction />} />
        <Route exact path='/friend/:id' element={<FriendsTransfer />} />
        <Route exact path='/plan' element={<Plan />} />
        <Route exact path='/plan/add' element={<PlanAdd />} />
        <Route exact path='/plan/view/:id' element={<PlanTransactions />} />
        <Route
          exact
          path='/plan/summary/:planId/:memberId'
          element={<PlanSummary />}
        />
        <Route exact path='/plan/edit/:id' element={<PlanEdit />} />
        <Route path='*' element={<h1>Not Found</h1>} />
      </Routes>
    </Router>
  );
};

export default MainBuilder;
