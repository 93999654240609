import React, { useEffect, useState } from 'react';

import wallet from '../../images/wallet.png';

import './dashboard.css';
import Navbar from '../inc/Navbar';
import { Link } from 'react-router-dom';
import { API_URL } from '../../config/config';
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import NotFound from '../inc/NotFound';
import { colorScheme, iconScheme } from '../../config/dataScheme';
import Loading from '../inc/Loading';
import Primaryloading from '../inc/Primaryloading';
import Walletloading from '../inc/Walletloading';
import { toast } from 'react-toastify';
import LoadingText from '../inc/LoadingText';

const Dashboard = () => {
  const [bank, setBank] = useState();
  const [amount, setAmount] = useState();
  const [primeBank, setPrimeBank] = useState();
  const [transactions, setTransactions] = useState();
  const [incomes, setIncomes] = useState();
  const [expenses, setExpenses] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [primaryLoad, setPrimaryLoad] = useState(true);
  const [walletLoading, setWalletLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const headers = {
    Authorization: `${localStorage.getItem('token')}`,
  };

  const bankSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const body = {
      name: bank,
      initialBalance: amount,
    };

    try {
      const response = await axios.post(`${API_URL}bank`, body, {
        headers: headers,
      });
      document.querySelector('#modal-close').click();
      console.log(response);
      setBank('');
      setAmount('');
      toast.success('Wallet added succesfully');
      setIsSubmitting(false);
    } catch (error) {
      // error code
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
      toast.error('please add your bank');
    }
  };

  const getWallet = async () => {
    const api_url = `${API_URL}bank`;

    const response = await axios.get(api_url, { headers: headers });
    console.log(response);
    const data = response.data;
    setPrimeBank(data[0]);
    console.log(data[0]);
    // setIsloaded(false);
    setPrimaryLoad(false);
    setWalletLoading(false);
  };
  const getTransactions = async () => {
    const api_url = `${API_URL}transaction`;

    const response = await axios.get(api_url, { headers: headers });

    const data = response.data;
    console.log(data);
    setTransactions(data);

    const filteredIncomes = data.filter((da) => {
      return da.type == 'expense' ? null : da;
    });

    // get only current month incomes
    const currentMonthIncomes = filteredIncomes.filter((da) => {
      return (
        moment(da.date, 'DD-MM-YYYY').format('MMMM') == moment().format('MMMM')
      );
    });

    const filteredExpenses = data.filter((da) => {
      return da.type == 'expense' ? da : null;
    });
    const currentMonthExpenses = filteredExpenses.filter((da) => {
      return (
        moment(da.date, 'DD-MM-YYYY').format('MMMM') == moment().format('MMMM')
      );
    });

    setIncomes(currentMonthIncomes);
    setExpenses(currentMonthExpenses);
    setIsLoading(false);
  };

  useEffect(() => {
    getWallet();
    getTransactions();
  }, []);
  return (
    <>
      <Navbar />
      <div className=' container'>
        <h3 className='mt-5'>Wallets</h3>
        <div className='row'>
          <div className='col-sm-4'>
            <div className='card p-4 mt-5'>
              {!primaryLoad ? (
                <div className='card-body '>
                  <div className='row'>
                    <div className='col-sm-2 '>
                      <img src={wallet} alt='' />
                    </div>
                    <div className='col-sm-9 ml-4  '>
                      <h5>
                        {}
                        {primeBank?.total
                          ? primeBank?.name
                          : 'Please Create a Wallet'}{' '}
                      </h5>
                      <h5
                        className={
                          primeBank?.total > 0 ? 'text-success' : 'text-danger'
                        }>
                        {primeBank?.total.toFixed(2)}{' '}
                        {primeBank?.total >= 0 && 'INR'}
                      </h5>
                    </div>
                  </div>
                </div>
              ) : (
                <Primaryloading />
              )}
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='rightContent  '>
              <div
                disabled={walletLoading}
                className='card text-center  top-card mt-5'
                data-toggle='modal'
                data-target='#myModal'>
                {walletLoading ? (
                  <Walletloading />
                ) : (
                  <p className='text-success' to='/login'>
                    Add new Wallet
                  </p>
                )}
              </div>
              <div className='card  text-center bottom-card  '>
                {walletLoading ? (
                  <Walletloading />
                ) : (
                  <p>
                    <Link to={`/wallets`}>Manage Wallets</Link>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='modal fade' id='myModal'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h4 className='modal-title'>Add wallet</h4>
              <button
                type='button'
                className='close'
                data-dismiss='modal'
                id='modal-close'>
                &times;
              </button>
            </div>

            <div className='modal-body'>
              <form onSubmit={(e) => bankSubmit(e)}>
                <div className='container-fluid addwalletPopup'>
                  <div className='row'>
                    <div className='col-sm-6'>
                      <label htmlFor=''>Bank</label>
                      <input
                        type='text'
                        className='form-control '
                        placeholder='Bank Name'
                        value={bank}
                        onChange={(e) => setBank(e.target.value)}
                      />
                    </div>
                    <div className='col-sm-6'>
                      <label htmlFor=''>Initial Amount</label>
                      <input
                        type='number'
                        className='form-control '
                        step={0.01}
                        placeholder='Enter the amount'
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-sm-9'></div>
                    <div className='col-sm-3 mt-3'>
                      <button
                        type='submit'
                        className='btn btn-block'
                        disabled={isSubmitting}>
                        {isSubmitting ? <LoadingText /> : <>Submit</>}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className='transactionSection mb-5'>
        <div
          className='container  mt-5
        '>
          <div className='row'>
            <div className='col-sm-6'>
              <div class='card text-left'>
                {!isLoading ? (
                  <div class='card-body'>
                    <div className='ml-3'>
                      <h4>Income</h4>
                      <p className='text-secondary'>
                        {moment().format('MMM YYYY')}
                      </p>
                    </div>
                    <table className='table table-borderless'>
                      <tbody>
                        {incomes && incomes.length > 0 ? (
                          <>
                            {incomes.map((transaction) => {
                              return (
                                <tr key={transaction._id}>
                                  <td>
                                    <div className='d-flex align-items-center '>
                                      {transaction.isTransfer ? (
                                        <div
                                          className='img-category '
                                          style={{
                                            backgroundColor: 'black',
                                          }}>
                                          <ion-icon name='swap-horizontal' />
                                        </div>
                                      ) : transaction.isPlan ? (
                                        <div
                                          className='img-category '
                                          style={{
                                            backgroundColor: '#a6c1ee',
                                          }}>
                                          <ion-icon name='planet-outline'></ion-icon>
                                        </div>
                                      ) : transaction.type ===
                                        'initialdeposit' ? (
                                        <div
                                          className='img-category'
                                          style={{
                                            backgroundColor: '#1AA9CA',
                                          }}>
                                          <ion-icon name='server-outline' />
                                        </div>
                                      ) : transaction.category ? (
                                        <div
                                          className='img-category'
                                          style={{
                                            backgroundColor:
                                              colorScheme[
                                                transaction.category.color - 1
                                              ].code,
                                          }}>
                                          <ion-icon
                                            name={
                                              iconScheme[
                                                transaction.category.icon - 1
                                              ].name
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          className='img-category'
                                          style={{
                                            backgroundColor: 'black',
                                          }}>
                                          <ion-icon name='reader-outline' />
                                        </div>
                                      )}

                                      <div>
                                        <h5 className='ml-2'>
                                          {transaction.isTransfer
                                            ? 'Transfer'
                                            : transaction.isSelfTransfer
                                            ? 'Self Transfer'
                                            : transaction.isPlan
                                            ? 'Plan Transaction'
                                            : transaction.type ===
                                              'initialdeposit'
                                            ? 'Initial Deposit'
                                            : transaction.category?.name ||
                                              'Uncategorized'}
                                          {/* {category
                                                ? category.name
                                                : 'Uncategorized'} */}
                                        </h5>
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <p>
                                      {moment(
                                        transaction.date,
                                        'DD-MM-YYYY'
                                      ).format('DD MMM YYYY')}
                                    </p>
                                  </td>

                                  <td className='text-right'>
                                    <h5
                                      className={
                                        transaction.type === 'expense'
                                          ? 'text-danger'
                                          : 'text-success'
                                      }>
                                      {transaction.type === 'expense'
                                        ? '-'
                                        : '+'}
                                      {transaction.amount.toFixed(2)} INR
                                    </h5>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <NotFound
                            title={'No Transactions'}
                            subtitle={'Add a transaction to get started'}
                          />
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Loading />
                )}
              </div>
            </div>

            <div className='col-sm-6'>
              <div class='card text-left'>
                {!isLoading ? (
                  <div class='card-body'>
                    <div className='ml-3'>
                      <h4>Expenses</h4>
                      <p className='text-secondary'>
                        {moment().format('MMM YYYY')}
                      </p>
                    </div>
                    <table className='table table-borderless'>
                      <tbody>
                        {expenses && expenses.length > 0 ? (
                          <>
                            {expenses.map((transaction) => {
                              return (
                                <tr key={transaction._id}>
                                  <td>
                                    <div className='d-flex align-items-center '>
                                      {transaction.isTransfer ? (
                                        <div
                                          className='img-category '
                                          style={{
                                            backgroundColor: 'black',
                                          }}>
                                          <ion-icon name='swap-horizontal' />
                                        </div>
                                      ) : transaction.isPlan ? (
                                        <div
                                          className='img-category '
                                          style={{
                                            backgroundColor: '#a6c1ee',
                                          }}>
                                          <ion-icon name='planet-outline'></ion-icon>
                                        </div>
                                      ) : transaction.type ===
                                        'initialdeposit' ? (
                                        <div
                                          className='img-category'
                                          style={{
                                            backgroundColor: '#1AA9CA',
                                          }}>
                                          <ion-icon name='server-outline' />
                                        </div>
                                      ) : transaction.category ? (
                                        <div
                                          className='img-category'
                                          style={{
                                            backgroundColor:
                                              colorScheme[
                                                transaction.category.color - 1
                                              ].code,
                                          }}>
                                          <ion-icon
                                            name={
                                              iconScheme[
                                                transaction.category.icon - 1
                                              ].name
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <div
                                          className='img-category'
                                          style={{
                                            backgroundColor: 'black',
                                          }}>
                                          <ion-icon name='reader-outline' />
                                        </div>
                                      )}

                                      <div>
                                        <h5 className='ml-2'>
                                          {transaction.isTransfer
                                            ? 'Transfer'
                                            : transaction.isSelfTransfer
                                            ? 'Self Transfer'
                                            : transaction.isPlan
                                            ? 'Plan Transaction'
                                            : transaction.type ===
                                              'initialdeposit'
                                            ? 'Initial Deposit'
                                            : transaction.category?.name ||
                                              'Uncategorized'}
                                          {/* {category
                                                ? category.name
                                                : 'Uncategorized'} */}
                                        </h5>
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <p>
                                      {moment(
                                        transaction.date,
                                        'DD-MM-YYYY'
                                      ).format('DD MMM YYYY')}
                                    </p>
                                  </td>
                                  <td className='text-right'>
                                    <h5
                                      className={
                                        transaction.type === 'expense'
                                          ? 'text-danger'
                                          : 'text-success'
                                      }>
                                      {transaction.type === 'expense'
                                        ? '-'
                                        : '+'}
                                      {transaction.amount.toFixed(2)} INR
                                    </h5>
                                  </td>
                                </tr>
                              );
                            })}
                          </>
                        ) : (
                          <NotFound
                            title={'No Transactions'}
                            subtitle={'Add a transaction to get started'}
                          />
                        )}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
