import React from 'react';
// router dom
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from '../components/Login/Login';
import Register from '../components/Login/Register';

const NoAuthBuilder = () => {
  return (
    <Router>
      <Routes>
        <Route exact path='/' element={<Login />} />
        <Route exact path='/login' element={<Login />} />
        <Route exact path='/register' element={<Register />} />
        <Route path='*' element={<h1>Not Found</h1>} />
      </Routes>
    </Router>
  );
};

export default NoAuthBuilder;
