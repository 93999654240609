import React, { useEffect, useState } from 'react';

import './transactions.css';
import Navbar from '../inc/Navbar';
import { API_URL } from '../../config/config';
import axios from 'axios';
import { iconScheme, colorScheme } from '../../config/dataScheme';
import Loading from '../inc/Loading';
import moment from 'moment';
import NotFound from '../inc/NotFound';
import Primaryloading from '../inc/Primaryloading';
import { useParams } from 'react-router-dom';
const CategoryTransactions = () => {
  const params = useParams();
  const { id } = params;

  const [transactions, setTransactions] = useState([]);
  const [currentBank, setCurrentBank] = useState({});
  const [category, setCategory] = useState({});
  const [income, setIncome] = useState(0);
  const [expense, setExpense] = useState(0);

  const [isLoaded, setIsLoaded] = useState(false);
  const [primaryLoading, setPrimaryLoading] = useState(false);

  useEffect(() => {
    getTransactions();
    getCurrentBank();
    getCatogory();
  }, []);

  const headers = {
    Authorization: `${localStorage.getItem('token')}`,
  };

  const getTransactions = async () => {
    const api_url = `${API_URL}transaction/category/${id}`;

    const response = await axios.get(api_url, { headers: headers });

    const data = await response.data;
    var exp = 0;
    var inc = 0;
    data.map((da) => {
      console.log(da.amount);
      if (da.type === 'expense') {
        exp = exp + da.amount;
      } else {
        inc = inc + da.amount;
      }
    });

    setIncome(inc);
    setExpense(exp);

    setTransactions(data);
    setIsLoaded(true);
    setPrimaryLoading(true);
  };
  const getCatogory = async () => {
    const api_url = `${API_URL}category`;

    const response = await axios.get(api_url, { headers: headers });

    const data = response.data;
    const rescategory = data.find((cat) => cat._id === id);
    setCategory(rescategory);
  };

  const getCurrentBank = async () => {
    const api_url = `${API_URL}bank`;
    try {
      const response = await axios.get(api_url, { headers: headers });
      const data = await response.data;
      const rescurrentBank = data.find((bank) => bank._id === id);
      setCurrentBank(rescurrentBank);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="counter-container">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  {primaryLoading ? (
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="icon-container category-icon">
                          <div
                            className="icon-holder"
                            style={{
                              backgroundColor:
                                colorScheme[category?.color - 1]?.code,
                            }}>
                            <ion-icon
                              name={
                                iconScheme[category?.icon - 1]?.name
                              }></ion-icon>
                          </div>
                          <h3 className="text-capitalize">{category?.name}</h3>
                        </div>

                        <h4>
                          Income <br />
                          <span className="text-success">
                            {income.toFixed(2)} INR
                          </span>
                        </h4>
                        <h4>
                          Expense <br />
                          <span className="text-danger">
                            {expense.toFixed(2)} INR
                          </span>
                        </h4>
                      </div>
                    </div>
                  ) : (
                    <Primaryloading />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="transactionSection ">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="card mt-5">
                  <div className="card-body">
                    {isLoaded ? (
                      <div className="row  ">
                        <table className="table table-borderless">
                          <tbody>
                            {transactions && transactions.length > 0 ? (
                              <>
                                {transactions.map((transaction) => {
                                  return (
                                    <tr key={transaction._id}>
                                      <td>
                                        <p>{transaction.bank?.name}</p>
                                      </td>
                                      <td>
                                        <p>
                                          {moment(
                                            transaction.date,
                                            'DD-MM-YYYY'
                                          ).format('DD MMM YYYY')}
                                        </p>
                                      </td>
                                      <td>
                                        <p>{transaction.note}</p>
                                      </td>

                                      <td className="text-right">
                                        <h5
                                          className={
                                            transaction.type === 'expense'
                                              ? 'text-danger'
                                              : 'text-success'
                                          }>
                                          {transaction.type === 'expense'
                                            ? '-'
                                            : '+'}
                                          {Number(transaction.amount).toFixed(
                                            2
                                          )}{' '}
                                          INR
                                        </h5>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <NotFound
                                title={'No Transactions'}
                                subtitle={'Add a transaction to get started'}
                              />
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoryTransactions;
