import React from 'react';

const Primaryloading = () => {
  return (
    <>
      <lottie-player
        src='https://assets5.lottiefiles.com/packages/lf20_lqnl5fdk.json'
        background='transparent'
        className='m-auto'
        loop
        autoplay
        style={{
          height: '100px',
          width: '200px',
          margin: 'auto',
        }}></lottie-player>
    </>
  );
};

export default Primaryloading;
