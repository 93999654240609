import React, { useState, useEffect } from 'react';
import Navbar from '../inc/Navbar';
import './banktransfer.css';
import moment from 'moment';
import { API_URL } from '../../config/config';
import axios from 'axios';
import LoadingText from '../inc/LoadingText';
import { toast } from 'react-toastify';
import Loading from '../inc/Loading';

const Banktransfer = () => {
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [amount, setAmount] = useState();
  const [note, setNote] = useState('');
  const [extraamount, setExtraAmount] = useState(0);
  const [bank, setBank] = useState('');
  const [tobank, setTobank] = useState('');
  const [banks, setBanks] = useState([]);
  const [isloaded, setIsLoaded] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const headers = {
    Authorization: `${localStorage.getItem('token')}`,
  };
  const getapi = async () => {
    try {
      const bank_url = `${API_URL}bank`;
      const bank_response = await axios.get(bank_url, { headers: headers });
      const bank_data = await bank_response.data;
      setIsLoaded(true);
      setBank(bank_data[0]._id);
      setBanks(bank_data);
      setTobank(bank_data[0]._id);
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const formatedDate = moment(date).format('DD-MM-YYYY');
      console.log(formatedDate);
      const body = {
        fromBank: bank,
        toBank: tobank,
        date: formatedDate,
        bankAmount: parseFloat(amount),
        note,
        extraAmount: parseFloat(extraamount),
      };
      const url = `${API_URL}bank/transfer`;
      const api_response = await axios.post(url, body, { headers: headers });
      console.log(api_response);

      setDate(formatedDate);
      setAmount('');
      setNote('');
      setExtraAmount('');
      setIsSubmitting(false);

      toast.success('Send Transfer Successfully');
    } catch (error) {
      console.log(error);
      toast.error(error);
    }
  };

  useEffect(() => {
    getapi();
  }, []);

  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="mb-4">Bank Transfer</h3>

            <div className="container ">
              <div className="card mt-5">
                {isloaded ? (
                  <div className="card-body">
                    <div className="row mt-3">
                      <div className="col-sm-10 offset-md-1">
                        <form
                          action=""
                          className="mt-3 accountForm"
                          onSubmit={(e) => submitHandler(e)}>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor=""> From Bank</label>
                                <select
                                  required
                                  className="form-control"
                                  name="fromBank"
                                  onChange={(e) => setBank(e.target.value)}>
                                  {banks &&
                                    banks.map((bank) => (
                                      <option key={bank._id} value={bank._id}>
                                        {bank.name} ({bank.total.toFixed(2)})
                                      </option>
                                    ))}
                                </select>
                              </div>
                              <div className="form-group">
                                <label htmlFor="">Bank Amount</label>
                                <input
                                  required
                                  type="number"
                                  step={0.01}
                                  className="form-control"
                                  placeholder="amount"
                                  name="amount"
                                  value={amount}
                                  onChange={(e) => setAmount(e.target.value)}
                                />
                              </div>

                              <div className="form-group">
                                <label htmlFor="">Notes</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  name="note"
                                  value={note}
                                  onChange={(e) => setNote(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="">To Bank </label>
                                <select
                                  className="form-control"
                                  name="toBank"
                                  onChange={(e) => setTobank(e.target.value)}>
                                  {banks &&
                                    banks.map((bank) => (
                                      <option key={bank._id} value={bank._id}>
                                        {bank.name} ({bank.total.toFixed(2)})
                                      </option>
                                    ))}
                                </select>
                              </div>

                              <div className="form-group">
                                <label htmlFor="">Extra Amount</label>
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="0.00"
                                  name="extraAmount"
                                  value={extraamount}
                                  step={0.01}
                                  onChange={(e) =>
                                    setExtraAmount(e.target.value)
                                  }
                                />
                              </div>
                              <div className="form-group">
                                <label htmlFor="">Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="date"
                                  value={date}
                                  onChange={(e) => setDate(e.target.value)}
                                  max={moment().format('YYYY-MM-DD')}
                                />
                              </div>
                            </div>
                            <div className="col-sm-3"></div>
                            <div className="col-sm-6 mt-4">
                              <button
                                className="btn btn  btn-block updateBtn "
                                disabled={isSubmitting}
                                type="submit">
                                {isSubmitting ? (
                                  <LoadingText />
                                ) : (
                                  <>Send Transfer</>
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banktransfer;
