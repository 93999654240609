import React, { useEffect, useState } from 'react';

import './transactions.css';
import Navbar from '../inc/Navbar';
import { API_URL } from '../../config/config';
import axios from 'axios';
import { iconScheme, colorScheme } from '../../config/dataScheme';
import Loading from '../inc/Loading';
import moment from 'moment';
import NotFound from '../inc/NotFound';
import { useParams } from 'react-router-dom';
import Primaryloading from '../inc/Primaryloading';
const BankTransaction = () => {
  const params = useParams();
  const { id } = params;

  const [transactions, setTransactions] = useState([]);
  const [currentBank, setCurrentBank] = useState({});

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getTransactions();
    getCurrentBank();
  }, []);

  const headers = {
    Authorization: `${localStorage.getItem('token')}`,
  };

  const getTransactions = async () => {
    const api_url = `${API_URL}transaction/bank/${id}`;

    const response = await axios.get(api_url, { headers: headers });

    const data = await response.data;
    var exp = 0;
    var inc = 0;
    data.map((da) => {
      console.log(da.amount);
      if (da.type === 'expense') {
        exp = exp + da.amount;
      } else {
        inc = inc + da.amount;
      }
    });

    setTransactions(data);
    setIsLoaded(true);
  };

  const getCurrentBank = async () => {
    const api_url = `${API_URL}bank`;
    try {
      const response = await axios.get(api_url, { headers: headers });
      const data = await response.data;
      const rescurrentBank = data.find((bank) => bank._id === id);
      setCurrentBank(rescurrentBank);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="counter-container">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  {!isLoaded ? (
                    <Primaryloading />
                  ) : (
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <h3 className="text-capitalize">{currentBank?.name}</h3>
                        <h4
                          className={
                            currentBank?.total > 0
                              ? ` text-success`
                              : 'text-danger'
                          }>
                          {currentBank?.total?.toFixed(2)} INR
                        </h4>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="transactionSection ">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="card mt-5">
                  <div className="card-body">
                    {isLoaded ? (
                      <div className="row  ">
                        <table className="table table-borderless">
                          <tbody>
                            {transactions && transactions.length > 0 ? (
                              <>
                                {transactions.map((transaction) => {
                                  return (
                                    <tr key={transaction._id}>
                                      <td>
                                        <div className="d-flex align-items-center ">
                                          {transaction.isTransfer ? (
                                            <div
                                              className="img-category "
                                              style={{
                                                backgroundColor: 'black',
                                              }}>
                                              <ion-icon name="swap-horizontal" />
                                            </div>
                                          ) : transaction.type ===
                                            'initialdeposit' ? (
                                            <div
                                              className="img-category"
                                              style={{
                                                backgroundColor: '#1AA9CA',
                                              }}>
                                              <ion-icon name="server-outline" />
                                            </div>
                                          ) : transaction.category ? (
                                            <div
                                              className="img-category"
                                              style={{
                                                backgroundColor:
                                                  colorScheme[
                                                    transaction.category.color -
                                                      1
                                                  ].code,
                                              }}>
                                              <ion-icon
                                                name={
                                                  iconScheme[
                                                    transaction.category.icon -
                                                      1
                                                  ].name
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className="img-category"
                                              style={{
                                                backgroundColor: 'black',
                                              }}>
                                              <ion-icon name="reader-outline" />
                                            </div>
                                          )}

                                          <div>
                                            <h5 className="ml-2">
                                              {transaction.isTransfer
                                                ? 'Transfer'
                                                : transaction.isSelfTransfer
                                                ? 'Self Transfer'
                                                : transaction.type ===
                                                  'initialdeposit'
                                                ? 'Initial Deposit'
                                                : transaction.category?.name ||
                                                  'Uncategorized'}
                                              {/* {category
                                                ? category.name
                                                : 'Uncategorized'} */}
                                            </h5>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <p>{transaction.bank?.name}</p>
                                      </td>
                                      <td>
                                        <p>
                                          {moment(
                                            transaction.date,
                                            'DD-MM-YYYY'
                                          ).format('DD MMM YYYY')}
                                        </p>
                                      </td>
                                      <td>
                                        <p>{transaction.note}</p>
                                      </td>

                                      <td className="text-right">
                                        <h5
                                          className={
                                            transaction.type === 'expense'
                                              ? 'text-danger'
                                              : 'text-success'
                                          }>
                                          {transaction.type === 'expense'
                                            ? '-'
                                            : '+'}
                                          {Number(transaction.amount).toFixed(
                                            2
                                          )}{' '}
                                          INR
                                        </h5>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <NotFound
                                title={'No Transactions'}
                                subtitle={'Add a transaction to get started'}
                              />
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankTransaction;
