import React from 'react';
import badge from '../../images/checked.png';
const Primaryverified = () => {
  return (
    <img
      src={badge}
      style={{
        width: '20px',
      }}
      alt=''
    />
  );
};

export default Primaryverified;
