import React, { useEffect, useState } from 'react';

import './transactions.css';
import Navbar from '../inc/Navbar';
import { API_URL } from '../../config/config';
import axios from 'axios';
import { iconScheme, colorScheme } from '../../config/dataScheme';
import Swal from 'sweetalert2';
import Loading from '../inc/Loading';
import LoadingText from '../inc/LoadingText';
import moment from 'moment';
import NotFound from '../inc/NotFound';
import Primaryloading from '../inc/Primaryloading';
import NoeditButton from '../inc/NoeditButton';
import { toast } from 'react-toastify';
const Transactions = () => {
  const [categories, setCategories] = useState();
  const [currentCategory, setCurrentCategory] = useState();
  const [transactions, setTransactions] = useState([]);
  const [banks, setBanks] = useState([]);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [primaryLoading, setPrimaryLoading] = useState(true);

  const [addcategory, setAddcategory] = useState();
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [note, setNote] = useState();
  const [type, setType] = useState('expense');
  const [amount, setAmount] = useState();
  const [currentBank, setCurrentBank] = useState();

  const [currenttransaction, setCurrenttransaction] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [totalIncome, setTotalIncome] = useState(0);

  useEffect(() => {
    getCatogory();
    getBanks();
    getTransactions();
  }, []);

  const headers = {
    Authorization: `${localStorage.getItem('token')}`,
  };
  const getCatogory = async () => {
    const api_url = `${API_URL}category`;

    const response = await axios.get(api_url, { headers: headers });

    const data = response.data;
    setCategories(data);
  };
  const getBanks = async () => {
    const api_url = `${API_URL}bank`;

    const response = await axios.get(api_url, { headers: headers });

    const data = response.data;
    setBanks(data);

    const total = response.data.reduce((acc, curr) => {
      return acc + curr.total;
    }, 0);

    setCurrentBank(data[0]);

    setTotalAmount(total);
  };

  const getTransactions = async () => {
    const api_url = `${API_URL}transaction`;

    const response = await axios.get(api_url, { headers: headers });
    const data = await response.data;

    console.log(response.data);

    var exp = 0;
    var inc = 0;
    data.map((da) => {
      if (da.type === 'expense') {
        exp = exp + da.amount;
      } else {
        inc = inc + da.amount;
      }
    });
    setTotalExpense(exp);
    setTotalIncome(inc);
    setTransactions(data);
    setIsLoaded(true);
    setPrimaryLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const api_url = `${API_URL}transaction`;
    const body = {
      category: currentCategory,
      date: moment(date).format('DD-MM-YYYY'),
      note: note,
      type: type,
      amount: amount,
      bank: currentBank,
    };
    if (!currentCategory) {
      // Swal.fire('Error', 'Please select a category', 'error');
      toast.error('Please select a category ');
      return;
    }
    if (!currentBank) {
      // Swal.fire('Error', 'Please select a Bank', 'error');
      toast.error('Please select a Bank');
      return;
    }
    setIsSubmitting(true);

    try {
      const response = await axios.post(api_url, body, { headers: headers });
      const data = response.data.data;
      console.log(data);
      setIsSubmitting(false);
      setTransactions([
        // data,
        { ...data, date: moment(data.date, 'YYYY-MM-DD').format('DD-MM-YYYY') },
        ...transactions,
      ]);
      document.querySelector('#modal-close-button').click();
      // Swal.fire('Success', 'Transaction has been added', 'success');
      toast.success('Transaction has been added successfully');

      setDate(moment().format('DD-MM-YYYY'));

      setDate(moment().format('YYYY-MM-DD'));

      setCurrentCategory(null);
      setNote('');
      setAmount('');
      setType('expense');
      setAddcategory('');
    } catch (error) {
      console.log(error);
      toast.error(error.response.data);
    }
  };

  const handleDelete = async (id) => {
    const api_url = `${API_URL}transaction/${id}`;

    const res = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });
    if (res.isConfirmed) {
      try {
        const filteredTransactions = transactions.filter(
          (item) => item._id !== id
        );
        setTransactions(filteredTransactions);
        await axios.delete(
          api_url,

          {
            headers: headers,
          }
        );
      } catch (error) {
        toast.error(error.response.data);
        // Swal.fire('Error', error.response.data.message, 'error');
      }
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    console.log(currenttransaction.type);

    const body = {
      // category: currenttransaction.category._id,
      // bank: currenttransaction.bank,
      note: currenttransaction.note,
      amount: currenttransaction.amount,
      type: currenttransaction.type,
      date: currenttransaction.date,
    };
    console.log(body);

    try {
      setTransactions(
        transactions.map((transaction) =>
          transaction._id === currenttransaction._id
            ? currenttransaction
            : transaction
        )
      );
      console.log(currenttransaction);
      document.querySelector('#modal-edit-close-button').click();

      toast.success('Updated Successfully');

      const url = `${API_URL}transaction/${currenttransaction?._id}`;
      await axios.put(url, body, { headers });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data);
    }
  };

  return (
    <>
      <Navbar />

      <div className='container mt-5'>
        <div className='counter-container'>
          <div className='col-sm-12'>
            <div className='row'>
              <div className='col-sm-3'>
                <div
                  disabled={primaryLoading}
                  className='card bg-success cardBtn '>
                  {primaryLoading ? (
                    <Primaryloading />
                  ) : (
                    <div
                      className='card-body'
                      data-toggle='modal'
                      data-target='#myModal'>
                      <div className=' justify-content-center align-items-center '>
                        <div>
                          <i className='fa-solid fa-circle-plus mr-2'></i>
                        </div>
                        <div>
                          <h5>Add Transaction</h5>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-3'>
                <div className='card card2'>
                  {primaryLoading ? (
                    <Primaryloading />
                  ) : (
                    <div className='card-body'>
                      <h6>Overall Balance</h6>
                      <h5
                        className={
                          totalAmount > 0 ? 'text-success' : 'text-danger'
                        }>
                        {totalAmount.toFixed(2)} INR
                      </h5>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-3'>
                <div className='card card2'>
                  {primaryLoading ? (
                    <Primaryloading />
                  ) : (
                    <div className='card-body '>
                      <h6>Total Period Expenses</h6>
                      <h5 className={'text-danger'}>
                        {' '}
                        {totalExpense > 0 && '-'}
                        {totalExpense.toFixed(2)} INR
                      </h5>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-sm-3'>
                <div className='card card2'>
                  {primaryLoading ? (
                    <Primaryloading />
                  ) : (
                    <div className='card-body '>
                      <h6>Total Period Income</h6>
                      <h5 className={'text-success'}>
                        {totalIncome > 0 && '+'}
                        {totalIncome.toFixed(2)} INR
                      </h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='transactionSection '>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-12'>
                <div className='card mt-5'>
                  <div className='card-body'>
                    {isLoaded ? (
                      <div className='row  '>
                        <table className='table table-borderless'>
                          <tbody>
                            {transactions && transactions.length > 0 ? (
                              <>
                                {transactions.map((transaction) => {
                                  return (
                                    <tr key={transaction._id}>
                                      <td>
                                        <div className='d-flex align-items-center '>
                                          {transaction.isTransfer ? (
                                            <div
                                              className='img-category '
                                              style={{
                                                backgroundColor: 'black',
                                              }}>
                                              <ion-icon name='swap-horizontal' />
                                            </div>
                                          ) : transaction.isPlan ? (
                                            <div
                                              className='img-category '
                                              style={{
                                                backgroundColor: '#a6c1ee',
                                              }}>
                                              <ion-icon name='planet-outline'></ion-icon>
                                            </div>
                                          ) : transaction.type ===
                                            'initialdeposit' ? (
                                            <div
                                              className='img-category'
                                              style={{
                                                backgroundColor: '#1AA9CA',
                                              }}>
                                              <ion-icon name='server-outline' />
                                            </div>
                                          ) : transaction.category ? (
                                            <div
                                              className='img-category'
                                              style={{
                                                backgroundColor:
                                                  colorScheme[
                                                    transaction.category.color -
                                                      1
                                                  ].code,
                                              }}>
                                              <ion-icon
                                                name={
                                                  iconScheme[
                                                    transaction.category.icon -
                                                      1
                                                  ].name
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className='img-category'
                                              style={{
                                                backgroundColor: 'black',
                                              }}>
                                              <ion-icon name='reader-outline' />
                                            </div>
                                          )}

                                          <div>
                                            <h5 className='ml-2'>
                                              {transaction.isTransfer
                                                ? 'Transfer'
                                                : transaction.isSelfTransfer
                                                ? 'Self Transfer'
                                                : transaction.isPlan
                                                ? 'Plan Transaction'
                                                : transaction.type ===
                                                  'initialdeposit'
                                                ? 'Initial Deposit'
                                                : transaction.category?.name ||
                                                  'Uncategorized'}
                                              {/* {category
                                                ? category.name
                                                : 'Uncategorized'} */}
                                            </h5>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <p>{transaction.bank?.name}</p>
                                      </td>
                                      <td>
                                        <p>
                                          {moment(
                                            transaction.date,
                                            'DD-MM-YYYY'
                                          ).format('DD MMM YYYY')}
                                        </p>
                                      </td>
                                      <td>
                                        <p>{transaction.note}</p>
                                      </td>

                                      <td className='text-right'>
                                        <h5
                                          className={
                                            transaction.type === 'expense'
                                              ? 'text-danger'
                                              : 'text-success'
                                          }>
                                          {transaction.type === 'expense'
                                            ? '-'
                                            : '+'}
                                          {Number(transaction.amount).toFixed(
                                            2
                                          )}{' '}
                                          INR
                                        </h5>
                                      </td>

                                      <td className='text-right'>
                                        {transaction.isTransfer ? (
                                          <NoeditButton />
                                        ) : transaction.isPlan ? (
                                          <NoeditButton />
                                        ) : transaction.type ==
                                          'initialdeposit' ? (
                                          <NoeditButton />
                                        ) : (
                                          <>
                                            <button
                                              className='btn btn-event btn-xs '
                                              data-toggle='modal'
                                              onClick={() =>
                                                setCurrenttransaction(
                                                  transaction
                                                )
                                              }
                                              data-target='#editModal'>
                                              <ion-icon name='create-outline'></ion-icon>
                                            </button>
                                            <button
                                              className='btn btn-event btn-xs ml-2'
                                              onClick={() =>
                                                handleDelete(transaction._id)
                                              }>
                                              <ion-icon name='trash'></ion-icon>
                                            </button>
                                          </>
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <NotFound
                                title={'No Transactions'}
                                subtitle={'Add a transaction to get started'}
                              />
                            )}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='modal addTransaction' id='myModal'>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Add Transactions</h5>
              <button
                type='button'
                id='modal-close-button'
                className='close'
                data-dismiss='modal'>
                &times;
              </button>
            </div>

            <form onSubmit={(e) => handleSubmit(e)} id='add-form'>
              <div className='modal-body'>
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='col-sm-2 w-100 '>
                      <label htmlFor=''>Category</label>
                      <div className='dropdown'>
                        <button
                          value={addcategory}
                          onChange={(e) => setAddcategory(e.target.value)}
                          type='button'
                          className='btn  dropdown-toggle  categoryBtn  d-flex align-items-center justify-content-center'
                          data-toggle='dropdown'>
                          {currentCategory ? (
                            <>
                              <ion-icon
                                style={{
                                  color:
                                    colorScheme[currentCategory.color - 1].code,
                                }}
                                name={iconScheme[currentCategory.icon - 1].name}
                                size={25}
                              />
                              <span>{currentCategory.name}</span>
                            </>
                          ) : (
                            <>
                              <span>Select Category</span>
                            </>
                          )}
                        </button>
                        <div className='dropdown-menu dropdownSize'>
                          {categories &&
                            categories.map((category) => (
                              <div
                                className='dropdown-item d-flex  align-items-center'
                                onClick={() => setCurrentCategory(category)}>
                                <div
                                  className='circle'
                                  style={{
                                    backgroundColor:
                                      colorScheme[category.color - 1].code,
                                  }}>
                                  <ion-icon
                                    name={
                                      iconScheme[category.icon - 1].name
                                    }></ion-icon>
                                </div>
                                <div className='ml-2'>
                                  <h5>{category.name}</h5>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-2'>
                      <label htmlFor=''>Bank</label>
                      <select
                        name=''
                        id=''
                        className='form-control'
                        required
                        onChange={(e) => setCurrentBank(e.target.value)}>
                        {currentBank == null}
                        {banks &&
                          banks.map((bank) => (
                            <option value={bank._id}>
                              {bank.name} ({bank.total.toFixed(2)})
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className='col-sm-2'>
                      <label htmlFor=''>Type</label>
                      <select
                        name=''
                        className='form-control'
                        required
                        id=''
                        value={type}
                        onChange={(e) => setType(e.target.value)}>
                        <option value='expense'>Expense</option>
                        <option value='income'>Income</option>
                      </select>
                    </div>
                    <div className='col-sm-2  '>
                      <label htmlFor=''>Date</label>
                      <input
                        className='form-control '
                        type='date'
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        required
                        max={moment().format('YYYY-MM-DD')}
                      />
                    </div>
                    <div className='col-sm-2'>
                      <label htmlFor=''>Note (Optional)</label>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Write Note'
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                      />
                    </div>

                    <div className='col-sm-2'>
                      <label htmlFor=''>Amount</label>
                      <input
                        className='form-control text-right'
                        type='number'
                        required
                        value={amount}
                        step='0.01'
                        min={1}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder='0'
                      />
                    </div>
                  </div>
                </div>
                <div className='container-fluid'>
                  <div className='row  '>
                    <div className='col-sm-2'></div>
                    <div className='col-sm-2'></div>
                    <div className='col-sm-2'></div>
                    <div className='col-sm-2'></div>
                    <div className='col-sm-2'></div>
                    <div className='col-sm-2 text-right mt-3'>
                      <button className='btn transactionBtn  mt-3 w-100 '>
                        {isSubmitting ? <LoadingText /> : <>Add Transaction</>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='modal addTransaction' id='editModal'>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Edit Transactions</h5>
              <button
                type='button'
                id='modal-edit-close-button'
                className='close'
                data-dismiss='modal'>
                &times;
              </button>
            </div>
            {currenttransaction && (
              <form onSubmit={(e) => handleEdit(e)} id='add-form'>
                <div className='modal-body'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-sm-2'>
                        <label htmlFor=''>Type</label>
                        <select
                          name=''
                          className='form-control text-capitalize'
                          required
                          id=''
                          value={currenttransaction.type}
                          onChange={(e) =>
                            setCurrenttransaction({
                              ...currenttransaction,
                              type: e.target.value,
                            })
                          }>
                          <option value='expense'>Expense</option>
                          <option value='income'>Income</option>
                        </select>
                      </div>
                      <div className='col-sm-2  '>
                        <label htmlFor=''>Date</label>
                        <input
                          className='form-control '
                          type='date'
                          value={moment(
                            currenttransaction.date,
                            'DD-MM-YYYY'
                          ).format('YYYY-MM-DD')}
                          onChange={(e) =>
                            setCurrenttransaction({
                              ...currenttransaction,
                              date: moment(e.target.value, 'YYYY-MM-DD').format(
                                'DD-MM-YYYY'
                              ),
                            })
                          }
                          required
                          max={moment().format('YYYY-MM-DD')}
                        />
                      </div>
                      <div className='col-sm-3'>
                        <label htmlFor=''>Note (Optional)</label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Write Note'
                          value={currenttransaction.note}
                          onChange={(e) =>
                            setCurrenttransaction({
                              ...currenttransaction,
                              note: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className='col-sm-2'>
                        <label htmlFor=''>Amount</label>
                        <input
                          className='form-control text-right'
                          type='number'
                          required
                          value={currenttransaction.amount}
                          step='0.01'
                          min={1}
                          onChange={(e) =>
                            setCurrenttransaction({
                              ...currenttransaction,
                              amount: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className='col-sm-3 mt-3  '>
                        <button
                          type='submit'
                          className='btn transactionBtn mt-3  '>
                          Update Transaction
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Transactions;
