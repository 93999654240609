import './Login.css';
import googleIcon from './google-icon.png';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { API_URL } from '../../config/config';
import { useState } from 'react';
import Swal from 'sweetalert2';
import LoadingText from '../inc/LoadingText';

const Login = () => {
  const navigate = useNavigate();
  const auth = getAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmiting, setisSubmitting] = useState(false);

  const handleAPILogin = async (token) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      };
      const response = await axios.post(
        `${API_URL}auth/login`,
        {},
        { headers }
      );
      return response.data.token;
    } catch (error) {
      Swal.fire('Oops...', error.response.data.message, 'error');
      setisSubmitting(false);
      return null;
    }
  };

  const handleError = (error) => {
    console.log(error);
    switch (error.code) {
      case 'auth/invalid-email':
        Swal.fire('Oops', 'Invalid Email ! Please try again');
        break;
      case 'auth/user-not-found':
        Swal.fire('Oops', 'User not found ! Please try again');
        break;
      case 'auth/wrong-password':
        Swal.fire('Oops', 'Wrong Password ! Please try again');
        break;
      case 'auth/user-disabled':
        Swal.fire('Oops', 'User disabled ! Please Contact Admin');
        break;
      case 'auth/too-many-requests':
        Swal.fire('Sorry !', 'Too many attempts ! Please try again later');
        break;
      case 'auth/email-already-in-use':
        Swal.fire('Oops', 'Email already in use ! Please Login');
        break;
      default:
        console.log(error.code);
        Swal.fire('Oops', 'Something went wrong ! Please try again');
        break;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setisSubmitting(true);

    try {
      const response = await signInWithEmailAndPassword(auth, email, password);
      const token = await response._tokenResponse.idToken;
      const api_token = await handleAPILogin(token);
      localStorage.setItem('token', api_token);
      window.location.href = '/dashboard';
    } catch (error) {
      console.log(error);
      handleError(error);
      setisSubmitting(false);
    }
  };

  const handleGoogleButton = async () => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();

      const response = await firebase.auth().signInWithPopup(provider);
      // console.log(response.user._delegate.accessToken);
      const token = await response.user._delegate.accessToken;
      // const token = await response._tokenResponse.idToken;
      const api_token = await handleAPILogin(token);
      if (api_token) {
        localStorage.setItem('token', api_token);
        window.location.href = '/dashboard';
      }
    } catch (error) {
      console.log(error);
      handleError(error);
      setisSubmitting(false);
    }
  };

  return (
    <>
      <div className="fullbackground-section">
        <section className="left-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-8 offset-md-2 main-login-form">
                <h3 className="page-title">Welcome Back !</h3>
                <p>Sign in your Account</p>
                <form onSubmit={(e) => handleSubmit(e)}>
                  <div className="form-group mb-3 ">
                    {/* <label>Email </label> */}
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email Address"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group mb-3">
                    {/* <label>Password</label> */}
                    <input
                      type="password"
                      className="form-control "
                      placeholder="Enter Password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="mb-2 text-right">
                    <p>
                      <Link to="/dashboard">forgot password?</Link>
                    </p>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="btn btn-block submit "
                      disabled={isSubmiting}>
                      {' '}
                      {isSubmiting ? (
                        <LoadingText />
                      ) : (
                        <>Sign in to your account</>
                      )}
                    </button>
                    <button
                      type="button"
                      className="btn btn-outline-dark btn-block g-sign text-center mt-3"
                      onClick={() => handleGoogleButton()}>
                      <img src={googleIcon} alt="" />
                      Sign in with Google
                    </button>
                  </div>

                  <div className="signup mt-3 text-center">
                    <p className="para">
                      create your free{' '}
                      <span
                        onClick={() => navigate('/register')}
                        className="name">
                        Exptracker
                      </span>{' '}
                      account
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <section className="right-section"></section>
      </div>
    </>
  );
};

export default Login;
