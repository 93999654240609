import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { API_URL } from '../../config/config';
import Loading from '../inc/Loading';
import LoadingText from '../inc/LoadingText';
import Navbar from '../inc/Navbar';
import NotFound from '../inc/NotFound';
import Primaryloading from '../inc/Primaryloading';
import primaryloading from '../inc/Primaryloading';
import './friends.css';
const Friends = () => {
  const navigate = useNavigate();
  const [friend, setFriend] = useState('');
  const [email, setEmail] = useState('');
  const [friends, setFriends] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [currentFriend, setCurrentFriend] = useState({});

  const headers = {
    Authorization: `${localStorage.getItem('token')}`,
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      const url = `${API_URL}friend`;

      const body = {
        name: friend,
        email,
      };
      const api_response = await axios.post(url, body, {
        headers: headers,
      });

      setFriends([...friends, { ...api_response.data.data, totalAmount: 0 }]);
      setFriend('');
      setEmail('');
      // Swal.fire('Good Job !', 'Friend added successfully', 'success');
      toast.success('Friend added Successfully');
      setIsSubmitting(false);
    } catch (error) {
      toast.error(error.response.data);
    }
  };

  const getFriends = async () => {
    try {
      const url = `${API_URL}friend`;
      const api_response = await axios.get(url, {
        headers: headers,
      });
      setFriends(api_response.data);
      setIsLoaded(true);

      console.log(api_response.data);
    } catch (error) {
      // error code
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }

      toast.error(error.response.data);
    }
  };
  const sendMailNotification = async (id) => {
    try {
      const url = `${API_URL}mail/${id}`;
      const api_response = await axios.get(url, {
        headers: headers,
      });
      toast.success('Mail Notification Sent Successfully !');
    } catch (error) {
      console.log(error.response.data);
      toast.error(error.response.data.message);
    }
  };

  const handleDelete = async (id) => {
    const url = `${API_URL}friend/delete/${id}`;

    const res = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (res.isConfirmed) {
      const delTrans = await Swal.fire({
        title: 'Do you want to delete the transaction of this transfer',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });
      try {
        setFriends(friends.filter((friend) => friend._id !== id));
        if (delTrans.isConfirmed) {
          const body = {
            deleteTransaction: true,
          };
          await axios.put(url, body, { headers: headers });
          return;
        }

        await axios.put(
          url,
          {},
          {
            headers: headers,
          }
        );
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    }
  };

  const editHandler = (e) => {
    e.preventDefault();
    try {
      const url = `${API_URL}friend/${currentFriend._id}`;
      const body = {
        name: currentFriend.name,
        email: currentFriend.email,
      };
      axios.put(url, body, {
        headers: headers,
      });
      setFriends(
        friends.map((friend) => {
          if (friend._id === currentFriend._id) {
            return currentFriend;
          }
          return friend;
        })
      );
      setCurrentFriend({});
      document.querySelector('#modal-close-button').click();
      // Swal.fire('Good Job !', 'Friend updated successfully', 'success');
      toast.success(' Update Successfully!');
    } catch (error) {
      toast.error(error.response.data);
    }
  };

  useEffect(() => {
    getFriends();
  }, []);

  return (
    <>
      <Navbar />
      <div className="full-body categories_page">
        <div className="container">
          <div className="row cat-section">
            <div className="col-sm-12">
              <h3>Add Friend</h3>
              <div className="card mt-3 border-rounded">
                {!isLoaded ? (
                  <Primaryloading />
                ) : (
                  <div className="card-body">
                    <form onSubmit={(e) => submitHandler(e)}>
                      <div className="row">
                        <div className="col-sm-4">
                          <label htmlFor="">Name</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter the Name"
                            value={friend}
                            required
                            onChange={(e) => setFriend(e.target.value)}
                          />
                        </div>
                        <div className="col-sm-4">
                          <label htmlFor="">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter the Email"
                            value={email}
                            required
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="col-sm-3 category-select ">
                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn  btn-block category-btn ">
                            {isSubmitting ? <LoadingText /> : <>Add Friend</>}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="transactionSection">
          <div className="container">
            <h3 className="mt-5">Friends</h3>
            <div className="row">
              <div className="col-sm-12">
                <div className="card mt-3">
                  <div className="card-body">
                    {isLoaded ? (
                      <div className="row">
                        <div className="col-sm-12">
                          {friends.length > 0 ? (
                            <div className="table table-responsive">
                              <table className="table table-borderless">
                                <tbody>
                                  {friends &&
                                    friends.map((friend) => (
                                      <tr key={friend._id}>
                                        <td>
                                          <b className="text-capitalize">
                                            {friend.name}
                                          </b>
                                        </td>
                                        <td>
                                          <p>{friend.email}</p>
                                        </td>
                                        <td>
                                          {friend.transferCount > 0 ? (
                                            <>
                                              {friend.transferCount > 1 ? (
                                                <>
                                                  {friend.transferCount}{' '}
                                                  Transfers
                                                </>
                                              ) : (
                                                <>
                                                  {friend.transferCount}{' '}
                                                  Transfer
                                                </>
                                              )}
                                            </>
                                          ) : (
                                            <>No Transfer</>
                                          )}
                                        </td>
                                        <td className="text-right">
                                          <h5
                                            className={
                                              friend.totalAmount < 0
                                                ? 'text-danger'
                                                : friend.totalAmount === 0
                                                ? 'text-muted'
                                                : 'text-success'
                                            }>
                                            {friend.totalAmount.toFixed(2)} INR
                                          </h5>
                                        </td>

                                        <td className="text-right">
                                          <button
                                            className="btn btn-event btn-xs mr-2 "
                                            onClick={() => {
                                              sendMailNotification(friend._id);
                                            }}>
                                            <ion-icon name="mail-unread-outline"></ion-icon>
                                          </button>
                                          <button
                                            className="btn btn-event btn-xs mr-2 "
                                            onClick={() => {
                                              navigate(`/friend/${friend._id}`);
                                            }}>
                                            <ion-icon name="eye-outline"></ion-icon>
                                          </button>
                                          <button
                                            className="btn btn-event btn-xs"
                                            data-toggle="modal"
                                            data-target="#myModal"
                                            onClick={() =>
                                              setCurrentFriend(friend)
                                            }>
                                            <ion-icon name="create-outline"></ion-icon>
                                          </button>
                                          <button
                                            className="btn btn-event btn-xs ml-2"
                                            onClick={() =>
                                              handleDelete(friend._id)
                                            }>
                                            <ion-icon name="trash-outline"></ion-icon>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <NotFound
                              title={'Oh No !'}
                              subtitle={
                                "Seems like you don't have any friends yet."
                              }
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      <Loading />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal" id="myModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4>Edit Friend</h4>
              <button
                type="button"
                className="close "
                id="modal-close-button"
                data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body popUp ">
              <form onSubmit={(e) => editHandler(e)}>
                <div className="row">
                  <div className="col-sm-4">
                    <div>
                      <input
                        type="text"
                        value={currentFriend.name}
                        onChange={(e) =>
                          setCurrentFriend({
                            ...currentFriend,
                            name: e.target.value,
                          })
                        }
                        className="form-control"
                      />
                    </div>
                  </div>{' '}
                  <div className="col-sm-4">
                    <div>
                      <input
                        type="text"
                        value={currentFriend.email}
                        onChange={(e) =>
                          setCurrentFriend({
                            ...currentFriend,
                            email: e.target.value,
                          })
                        }
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="btn  btn-block category-btn ">
                      {isSubmitting ? <LoadingText /> : <>Update Friend</>}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Friends;
