import React, { useEffect, useState } from 'react';

import './transactions.css';
import Navbar from '../inc/Navbar';
import { API_URL } from '../../config/config';
import axios from 'axios';
import { iconScheme, colorScheme } from '../../config/dataScheme';
import Loading from '../inc/Loading';
import moment from 'moment';
import NotFound from '../inc/NotFound';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BankTransaction from '../BankTransactions/Banktransactions';
import Primaryloading from '../inc/Primaryloading';

const FriendsTransfer = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const [transactions, setTransactions] = useState([]);
  const [isloaded, setIsLoaded] = useState(true);
  const [totalamount, setTotalamount] = useState(0);
  const [currentfriend, setCurrentfriend] = useState({});

  useEffect(() => {
    getTransfers();
    getapi();
    getfriends();
    // getbanks();
  }, []);

  const headers = {
    Authorization: `${localStorage.getItem('token')}`,
  };

  const getTransfers = async () => {
    const api_url = `${API_URL}transfer/friend/${id}`;
    console.log(id);
    try {
      const response = await axios.get(api_url, { headers: headers });
      const data = await response.data;
      console.log(data);
      setTransactions(data);
      var amount = 0;
      data.map((da) => {
        if (da.type === 'expense') {
          amount = amount - da.amount;
        } else {
          amount = amount + da.amount;
        }
      });

      setTotalamount(amount);
      setIsLoaded(false);
    } catch (error) {
      if (error.response.status === 500) {
        toast.error('Internal Server Error');
        navigate('/friends');
      }
    }
  };
  const getapi = async () => {
    const api_url = `${API_URL}transfer`;
    try {
      const response = await axios.get(api_url, { headers: headers });
      const data = await response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getfriends = async () => {
    const api_url = `${API_URL}friend`;
    try {
      const response = await axios.get(api_url, { headers: headers });
      const data = await response.data;
      console.log(data);
      setCurrentfriend(data.find((friend) => friend._id === id));
    } catch (error) {
      console.log(error);
    }
  };
  // const getbanks = async () => {
  //   const api_url = `${API_URL}bank`;
  //   try {
  //     const response = await axios.get(api_url, { headers: headers });
  //     const data = await response.data;
  //     console.log(data);
  //     const rescurrentbank = data.find((bank) => bank._id === id);
  //     setCurrentbank(rescurrentbank);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="counter-container">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  {isloaded ? (
                    <Primaryloading />
                  ) : (
                    <div className="card-body">
                      <div className="d-flex align-items-center justify-content-between">
                        <h3 className="text-capitalize">
                          {currentfriend?.name}
                        </h3>
                        <h4
                          className={
                            totalamount > 0 ? ` text-success` : 'text-danger'
                          }>
                          {totalamount?.toFixed(2)}
                          {''} {'INR'}
                        </h4>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="transactionSection ">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="card mt-5">
                  <div className="card-body">
                    {isloaded ? (
                      <Loading />
                    ) : (
                      <div className="row  ">
                        <table className="table table-borderless">
                          <tbody>
                            {transactions && transactions.length > 0 ? (
                              <>
                                {transactions.map((transaction) => {
                                  return (
                                    <tr key={transaction._id}>
                                      <td>
                                        <div className="d-flex align-items-center ">
                                          {transaction.isTransfer ? (
                                            <div
                                              className="img-category "
                                              style={{
                                                backgroundColor: 'black',
                                              }}>
                                              <ion-icon name="swap-horizontal" />
                                            </div>
                                          ) : transaction.type ===
                                            'initialdeposit' ? (
                                            <div
                                              className="img-category"
                                              style={{
                                                backgroundColor: '#1AA9CA',
                                              }}>
                                              <ion-icon name="server-outline" />
                                            </div>
                                          ) : transaction.category ? (
                                            <div
                                              className="img-category"
                                              style={{
                                                backgroundColor:
                                                  colorScheme[
                                                    transaction.category.color -
                                                      1
                                                  ].code,
                                              }}>
                                              <ion-icon
                                                name={
                                                  iconScheme[
                                                    transaction.category.icon -
                                                      1
                                                  ].name
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className="img-category"
                                              style={{
                                                backgroundColor: 'black',
                                              }}>
                                              <ion-icon name="reader-outline" />
                                            </div>
                                          )}

                                          <div>
                                            <h5 className="ml-2">
                                              {transaction.isTransfer
                                                ? 'Transfer'
                                                : transaction.type ===
                                                  'initialdeposit'
                                                ? 'Initial Deposit'
                                                : transaction.category?.name ||
                                                  'Transfer'}
                                              {/* {category
                                            ? category.name
                                            : 'Uncategorized'} */}
                                            </h5>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <p>
                                          {transaction?.transaction?.bank?.name}
                                        </p>
                                      </td>
                                      <td>
                                        <p>
                                          {moment(
                                            transaction.date,
                                            'DD-MM-YYYY'
                                          ).format('DD MMM YYYY')}
                                        </p>
                                      </td>
                                      <td>
                                        <p>{transaction.note}</p>
                                      </td>

                                      <td className="text-right">
                                        <h5
                                          className={
                                            transaction.type === 'expense'
                                              ? 'text-danger'
                                              : 'text-success'
                                          }>
                                          {transaction.type === 'expense'
                                            ? '-'
                                            : '+'}
                                          {Number(transaction.amount).toFixed(
                                            2
                                          )}{' '}
                                          INR
                                        </h5>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </>
                            ) : (
                              <NotFound
                                title={'No Transactions'}
                                subtitle={'Add a transaction to get started'}
                              />
                            )}
                          </tbody>
                        </table>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FriendsTransfer;
