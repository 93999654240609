import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { API_URL } from '../../config/config';
import Loading from '../inc/Loading';
import LoadingText from '../inc/LoadingText';
import Navbar from '../inc/Navbar';
import Notfound from '../inc/NotFound';
import Primaryverified from '../inc/Primaryverified';
import Primaryloading from '../inc/Primaryloading';
import './managewallet.css';

const Managewallet = () => {
  const navigate = useNavigate();
  const [wallet, setWallet] = useState([]);
  const [currentBank, setCurrentBank] = useState();
  const [isloaded, setIsloaded] = useState(true);
  const [bank, setBank] = useState();
  const [amount, setAmount] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    getWallet();
  }, []);

  const headers = {
    Authorization: `${localStorage.getItem('token')}`,
  };
  const getWallet = async () => {
    const api_url = `${API_URL}bank`;

    const response = await axios.get(api_url, { headers: headers });
    console.log(response);
    const data = response.data;
    setWallet(data);
    setIsloaded(false);
  };

  const handleDelete = async (id) => {
    const api_url = `${API_URL}bank/${id}`;

    const res = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (res.isConfirmed) {
      try {
        const filteredWallets = wallet.filter((item) => item._id !== id);
        setWallet(filteredWallets);
        await axios.delete(api_url, { headers: headers });
      } catch (error) {
        console.log(error);
        // Swal.fire('Error', error.response.data.message, 'error');
        toast.error(error.response.data);
      }
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    const url = `${API_URL}bank/${currentBank._id}`;
    const body = {
      name: currentBank.name,
    };

    try {
      // remove the existing one from wallet

      setWallet(
        wallet.map((wal) => (wal._id === currentBank._id ? currentBank : wal))
      );
      document.querySelector('#modal-close-button').click();
      toast.success('Bank Updated Successfully!');
      await axios.put(url, body, { headers: headers });
    } catch (error) {
      Swal.fire('Error', error.response.statusText, 'error');
      // toast.error(error.response.data);
    }
  };

  const bankSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const body = {
      name: bank,
      initialBalance: amount,
    };

    try {
      const response = await axios.post(`${API_URL}bank`, body, {
        headers: headers,
      });

      setBank('');
      setAmount('');
      // Swal.fire('Good Job !', 'Bank added successfully', 'success');
      toast.success('Wallet Added Successfully!');
      console.log(response);
      setIsSubmitting(false);
    } catch (error) {
      // error code
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }

      // Swal.fire('Oops...', error.response.data.message, 'error');
      toast.error('please add your Wallet');
    }
  };

  const handlePrimaryCheck = () => {
    toast.error('This is already primary');
  };

  const makePrimaryBank = async (id) => {
    const api_url = `${API_URL}bank/primary/${id}`;

    const res = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do You Want this Bank make Primary ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes!',
    });

    if (res.isConfirmed) {
      try {
        // get a old primary and make it to false
        var oldPrimaryBanks = wallet.filter((wal) => {
          return wal.isPrimary === true;
        });
        var oldPrimary = oldPrimaryBanks[0];
        oldPrimary = {
          ...oldPrimary,
          isPrimary: false,
        };

        // set old primary falsed data

        const removedFlags = wallet.map((wal) => {
          return wal._id === oldPrimary._id ? oldPrimary : wal;
        });

        // get the current primary from the id
        const filteredWallet = removedFlags.filter((wal) => {
          return wal._id === id;
        });
        var currentPrimary = filteredWallet[0];
        currentPrimary = {
          ...currentPrimary,
          isPrimary: true,
        };

        // set the current primary to true

        setWallet(
          removedFlags.map((wal) =>
            wal._id === currentPrimary._id ? currentPrimary : wal
          )
        );
        await axios.put(api_url, {}, { headers: headers });
      } catch (error) {
        console.log(error);
        // Swal.fire('Error', error.response.data.message, 'error');
        toast.error(error.response.data);
      }
    }
  };

  return (
    <>
      <Navbar />

      <div className="container mt-5">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="mb-4">Add Wallet</h3>
            <div className="card ">
              <div className="card-body">
                {isloaded ? (
                  <Primaryloading />
                ) : (
                  <form onSubmit={(e) => bankSubmit(e)}>
                    <div className="container-fluid addwalletPopup">
                      <div className="row addWalletsection">
                        <div className="col-sm-4">
                          <label htmlFor="">Bank</label>
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Bank Name"
                            value={bank}
                            onChange={(e) => setBank(e.target.value)}
                          />
                        </div>
                        <div className="col-sm-4">
                          <label htmlFor="">Initial Amount</label>
                          <input
                            type="number"
                            className="form-control "
                            step={0.01}
                            placeholder="Enter the amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                        <div className="col-sm-2 ">
                          <button
                            type="submit"
                            className="btn btn-block"
                            disabled={isSubmitting}>
                            {isSubmitting ? <LoadingText /> : <>Submit</>}
                          </button>
                        </div>
                        <div className="col-sm-2"></div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='full-body categories_page'> */}
      <div className="container mt-5">
        <div className="row cat-section">
          <div className="col-sm-12">
            <h3 className="">Manage Wallets</h3>
            <div className="row">
              <div className="col-sm-12 transactionSection">
                <div className="card mt-2">
                  {isloaded ? (
                    <Loading />
                  ) : (
                    <div className="card-body">
                      {wallet && wallet.length > 0 ? (
                        <div className="row">
                          <div className="table table-responsive ">
                            <table className="table table-borderless">
                              <tbody>
                                {wallet &&
                                  wallet.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        <b>{item.name} </b>
                                        {item.isPrimary && <Primaryverified />}
                                      </td>
                                      <td>
                                        <p>
                                          {item.transactions.length === 0 ? (
                                            'No Transaction'
                                          ) : (
                                            <>
                                              {item.transactions.length ===
                                              1 ? (
                                                <>
                                                  {item.transactions.length}{' '}
                                                  Transaction
                                                </>
                                              ) : (
                                                <>
                                                  {item.transactions.length}{' '}
                                                  Transactions
                                                </>
                                              )}
                                            </>
                                          )}
                                        </p>
                                      </td>
                                      <td className="text-right">
                                        {item.total > 0 ? (
                                          <b className="text-success">
                                            {item.total.toFixed(2)} INR
                                          </b>
                                        ) : (
                                          <b className="text-danger">
                                            {item.total.toFixed(2)} INR
                                          </b>
                                        )}
                                      </td>

                                      <td className="text-right">
                                        <button
                                          className="btn btn-event btn-xs mr-2"
                                          onClick={() => {
                                            navigate(
                                              `/transactions/${item._id}`
                                            );
                                          }}>
                                          <ion-icon name="eye-outline"></ion-icon>
                                        </button>
                                        <button
                                          className="btn btn-event btn-xs "
                                          data-toggle="modal"
                                          data-target="#myModal"
                                          onClick={() => {
                                            setCurrentBank(item);
                                          }}>
                                          <ion-icon name="create-outline"></ion-icon>
                                        </button>
                                        <button
                                          className="btn btn-event btn-xs ml-2"
                                          onClick={() => {
                                            handleDelete(item._id);
                                          }}>
                                          <ion-icon name="trash-outline"></ion-icon>
                                        </button>
                                        {item.isPrimary === false ? (
                                          <button
                                            className="btn btn-event btn-xs ml-2"
                                            onClick={() =>
                                              makePrimaryBank(item._id)
                                            }>
                                            <ion-icon name="checkmark-done-circle-outline"></ion-icon>
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-event btn-xs ml-2 "
                                            onClick={() =>
                                              handlePrimaryCheck()
                                            }>
                                            <ion-icon name="checkmark-done-circle-outline"></ion-icon>
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : (
                        <Notfound
                          title={'No wallet '}
                          subtitle={'Add your Wallet'}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="modal" id="myModal">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Edit Bank </h4>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      id="modal-close-button">
                      &times;
                    </button>
                  </div>

                  <div className="modal-body">
                    <form onSubmit={(e) => handleEdit(e)}>
                      <div className="container-fluid addwalletPopup">
                        <div className="row">
                          <div className="col-sm-12">
                            <label htmlFor="">Bank</label>
                            <input
                              value={currentBank?.name}
                              onChange={(e) => {
                                setCurrentBank({
                                  ...currentBank,
                                  name: e.target.value,
                                });
                              }}
                              type="text"
                              className="form-control "
                              placeholder="Bank Name"
                              required
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-9"></div>
                          <div className="col-sm-3 mt-3">
                            <button type="submit" className="btn btn-block">
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default Managewallet;
