import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { API_URL } from '../../config/config';
import Navbar from '../inc/Navbar';
import moment from 'moment';
import './Transfer.css';
import LoadingText from '../inc/LoadingText';
import Loading from '../inc/Loading';
import NotFound from '../inc/NotFound';
import Primaryloading from '../inc/Primaryloading';
import { toast } from 'react-toastify';

const Transfer = () => {
  const [friends, setFriends] = useState([]);
  const [banks, setBanks] = useState([]);

  const [transfers, setTransfers] = useState([]);

  const [currentTransfer, setCurrentTransfer] = useState();

  const [issubmitting, setIssubmitting] = useState(true);
  const [isloaded, setIsloaded] = useState(true);
  const [primaryloading, setPrimaryloading] = useState(true);

  const [notes, setNotes] = useState('');
  const [amount, setAmount] = useState('');
  const [friend, setFriend] = useState('');
  const [bank, setBank] = useState([]);

  const [type, setType] = useState('expense');
  const [date, setDate] = useState(moment().format('YYYY-MM-DD'));
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalExpense, setTotalExpense] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);

  const headers = {
    Authorization: `${localStorage.getItem('token')}`,
  };

  const getapi = async () => {
    try {
      const url = `${API_URL}friend`;
      const response = await axios.get(url, { headers: headers });
      const data = await response.data;

      setFriends(data);

      const bank_url = `${API_URL}bank`;
      const bank_response = await axios.get(bank_url, { headers: headers });
      const bank_data = await bank_response.data;

      console.log(bank_data[0]._id);
      setBank(bank_data[0]._id);
      setBanks(bank_data);

      setTotalBalance(bank_data[0].total);

      const transfer_url = `${API_URL}transfer`;
      const transfer_response = await axios.get(transfer_url, {
        headers: headers,
      });
      const transfer_data = await transfer_response.data;

      var exp = 0;
      var inc = 0;
      transfer_data.map((da) => {
        console.log(da);
        if (da.type === 'expense') {
          exp = exp + da.amount;
        } else {
          inc = inc + da.amount;
        }
      });

      setTransfers(transfer_data);
      setTotalExpense(exp);
      setTotalIncome(inc);
      setIsloaded(false);
      setPrimaryloading(false);
      setIssubmitting(false);
    } catch (error) {
      console.log(error);
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }

      Swal.fire('Oops...', error.response.data.message, 'error');
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    setIssubmitting(true);

    try {
      const formatedDate = moment(date).format('DD-MM-YYYY');
      console.log(formatedDate);

      const body = {
        bank,
        friend,
        type,
        date: formatedDate,
        note: notes,
        amount,
      };

      const url = `${API_URL}transfer`;
      const api_response = await axios.post(url, body, {
        headers: headers,
      });
      const data = await api_response.data.data;
      console.log(data);

      setTransfers([
        // data,
        { ...data, date: moment(data.date, 'YYYY-MM-DD').format('DD-MM-YYYY') },
        ...transfers,
      ]);

      setFriend('');
      setType('expense');
      setNotes('');
      setDate(moment().format('YYYY-MM-DD'));
      setAmount('');

      // trigger a click event on model-close-button
      document.querySelector('#modal-close-button').click();
      toast.success('Transfer has been added successfully');

      // Swal.fire('Good Job !', 'Transfer added successfully', 'success');
      setIssubmitting(false);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem('token');
        window.location.href = '/';
        return;
      }
      Swal.fire('Oops...', error.response.data.message, 'error');
      setIssubmitting(false);
    }
  };
  const deleteHandler = async (id) => {
    const url = `${API_URL}transfer/delete/${id}`;

    const res = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (res.isConfirmed) {
      const delTrans = await Swal.fire({
        title: 'Do you want to delete the transaction of this transfer',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });

      try {
        setTransfers(transfers.filter((transfer) => transfer._id !== id));

        if (delTrans.isConfirmed) {
          const body = {
            deleteTransaction: true,
          };
          await axios.put(url, body, { headers: headers });
          return;
        }
        await axios.put(url, {}, { headers: headers });
        return;
      } catch (error) {
        Swal.fire('Error', 'Something went wrong', 'error');
      }
    }
  };

  const editHandler = async (e) => {
    e.preventDefault();
    const body = {
      amount: currentTransfer?.amount,
      date: currentTransfer?.date,
      note: currentTransfer?.note,
      type: currentTransfer?.type,
    };

    try {
      const url = `${API_URL}transfer/${currentTransfer?._id}`;

      setTransfers(
        transfers.map((transfer) =>
          transfer._id === currentTransfer?._id ? currentTransfer : transfer
        )
      );
      document.querySelector('#modal-edit-close-button').click();
      toast.success('Updated Successfully');
      await axios.put(url, body, { headers });
    } catch (error) {
      console.log(error);
      // Swal.fire('Oops ! ', error.response.data.message, 'error');
      toast.error(error.response.data);
    }
  };

  useEffect(() => {
    getapi();
  }, []);
  return (
    <>
      <Navbar />
      <div className="container mt-5">
        <div className="counter-container">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-3">
                <div disabled={primaryloading} className="card bg-success">
                  {primaryloading ? (
                    <Primaryloading />
                  ) : (
                    <div
                      className="card-body"
                      data-target="#myModal"
                      data-toggle="modal">
                      <div className="d-flex justify-content-center align-items-center mt-2 ">
                        <div>
                          <i className="fa-solid fa-circle-plus mr-2"></i>
                        </div>
                        <div>
                          <h5>Add Transfer</h5>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-3">
                <div className="card card2">
                  {primaryloading ? (
                    <Primaryloading />
                  ) : (
                    <div className="card-body">
                      <h6>Current Wallet Balance</h6>
                      <h5 className={'text-success'}>
                        {totalBalance.toFixed(2)} INR
                      </h5>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-3">
                <div className="card card2">
                  {primaryloading ? (
                    <Primaryloading />
                  ) : (
                    <div className="card-body ">
                      <h6>Total Period Expenses</h6>
                      <h5 className="text-danger">
                        {totalExpense > 0 && '-'}
                        {totalExpense.toFixed(2)} INR
                      </h5>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-sm-3">
                <div className="card card2">
                  {primaryloading ? (
                    <Primaryloading />
                  ) : (
                    <div className="card-body ">
                      <h6>Total Period Income</h6>
                      <h5 className="text-success">
                        {totalIncome > 0 && '+'}
                        {totalIncome.toFixed(2)} INR
                      </h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="transactionSection">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="card mt-5">
                  {isloaded ? (
                    <Loading />
                  ) : (
                    <div className="card-body">
                      {transfers.length > 0 ? (
                        <div className="row  ">
                          <table className="table table-borderless">
                            <tbody>
                              {transfers &&
                                transfers.map((transfer) => (
                                  <tr key={transfer._id}>
                                    <td>
                                      <p className="text-capitalize">
                                        {transfer?.friend?.name}
                                      </p>
                                    </td>

                                    <td>
                                      <p className="text-capitalize">
                                        {transfer.transaction?.bank?.name}
                                      </p>
                                    </td>
                                    <td>
                                      <p>{transfer.note}</p>
                                    </td>
                                    <td>
                                      <p>
                                        {moment(
                                          transfer.date,
                                          'DD-MM-YYYY'
                                        ).format('DD MMM YYYY')}
                                      </p>
                                    </td>
                                    <td className="text-right">
                                      <h5
                                        className={
                                          transfer.type === 'expense'
                                            ? 'text-danger'
                                            : 'text-success'
                                        }>
                                        {transfer.type === 'expense'
                                          ? '-'
                                          : '+'}
                                        {Number(transfer.amount).toFixed(2)} INR
                                      </h5>
                                    </td>
                                    <td className="text-right">
                                      <button
                                        className="btn  mr-2 btn-event"
                                        data-toggle="modal"
                                        data-target="#editModal"
                                        onClick={() =>
                                          setCurrentTransfer(transfer)
                                        }>
                                        <ion-icon name="create-outline"></ion-icon>
                                      </button>
                                      <button
                                        className="btn  mr-2 btn-event "
                                        onClick={() =>
                                          deleteHandler(transfer._id)
                                        }>
                                        <ion-icon name="trash-outline"></ion-icon>
                                      </button>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <NotFound
                          title={'No Transfers'}
                          subtitle={'Add Transfer you have no transfer yet '}
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal addTransaction" id="myModal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Transfer</h5>
              <button
                type="button"
                className="close "
                id="modal-close-button"
                data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              <form action="" onSubmit={(e) => submitHandler(e)}>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-2">
                      <label>Bank</label>
                      <select
                        required
                        name=""
                        className="form-control"
                        id=""
                        onChange={(e) => setBank(e.target.value)}>
                        {banks &&
                          banks.map((bank) => (
                            <option key={bank._id} value={bank._id}>
                              {bank.name} ({bank.total.toFixed(2)})
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-sm-2">
                      <label>Friends</label>
                      <select
                        name=""
                        className="form-control text-capitalize"
                        id=""
                        required
                        value={friend}
                        onChange={(e) => setFriend(e.target.value)}>
                        <option value="">Select Friend</option>
                        {friends.map((friend) => (
                          <option key={friend._id} value={friend._id}>
                            {friend.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-sm-2">
                      <label htmlFor="">Type</label>
                      <select
                        name=""
                        className="form-control"
                        id=""
                        required
                        value={type}
                        onChange={(e) => setType(e.target.value)}>
                        <option value="income">Income</option>
                        <option value="expense">Expense</option>
                      </select>
                    </div>
                    <div className="col-sm-2  ">
                      <label htmlFor="">Date</label>
                      <input
                        className="form-control"
                        type="date"
                        required
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        max={moment().format('YYYY-MM-DD')}
                      />
                    </div>
                    <div className="col-sm-2">
                      <label htmlFor="">Note (Optional)</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Write Note"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-2">
                      <label htmlFor="">Amount</label>
                      <input
                        className="form-control text-right"
                        type="number"
                        step={0.01}
                        min={1}
                        placeholder="0"
                        required
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-fluid">
                  <div className="row  ">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-2"></div>
                    <div className="col-sm-2"></div>
                    <div className="col-sm-2"></div>
                    <div className="col-sm-2"></div>
                    <div className="col-sm-2 text-right mt-3">
                      <button
                        className="btn transactionBtn  mt-3  "
                        disabled={issubmitting}
                        type="submit">
                        {issubmitting ? <LoadingText /> : <> Add Transfer</>}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal addTransaction" id="editModal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Transfer</h5>
              <button
                type="button"
                className="close "
                id="modal-edit-close-button"
                data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
              {currentTransfer && (
                <form onSubmit={(e) => editHandler(e)}>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-sm-3">
                        <label htmlFor="">Type</label>
                        <select
                          name=""
                          className="form-control text-capitalize"
                          id=""
                          required
                          value={currentTransfer.type}
                          onChange={(e) =>
                            setCurrentTransfer({
                              ...currentTransfer,
                              type: e.target.value,
                            })
                          }>
                          <option value="income">Income</option>
                          <option value="expense">Expense</option>
                        </select>
                      </div>
                      <div className="col-sm-3  ">
                        <label htmlFor="">Date</label>
                        <input
                          className="form-control"
                          type="date"
                          required
                          value={moment(
                            currentTransfer.date,
                            'DD-MM-YYYY'
                          ).format('YYYY-MM-DD')}
                          onChange={(e) =>
                            setCurrentTransfer({
                              ...currentTransfer,
                              date: moment(e.target.value, 'YYYY-MM-DD').format(
                                'DD-MM-YYYY'
                              ),
                            })
                          }
                        />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="">Note (Optional)</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Write Note"
                          value={currentTransfer.note}
                          onChange={(e) =>
                            setCurrentTransfer({
                              ...currentTransfer,
                              note: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="col-sm-3">
                        <label htmlFor="">Amount</label>
                        <input
                          className="form-control text-right"
                          type="number"
                          step={0.01}
                          min={1}
                          required
                          value={currentTransfer.amount}
                          onChange={(e) =>
                            setCurrentTransfer({
                              ...currentTransfer,
                              amount: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid">
                    <div className="row  ">
                      <div className="col-sm-12 text-right mt-3">
                        <button
                          className="btn transactionBtn  mt-3  "
                          type="submit">
                          Update Transfer
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Transfer;
